<template>
  <v-container fluid v-if="OTaircraft">
    <v-col cols="12">
      <v-sheet class="mb-4">
        <v-card-title class="text-uppercase">
          <h5 class="primary--text">
            Réalisation d'un nouvel ordre de travail -
            {{ OTaircraft.immatriculation }}
          </h5>
          <v-spacer></v-spacer>
          <v-btn
            class="primary--text"
            outlined
            @click="saveOT(true)"
            v-if="workOrder"
            >Enregistrer et quitter</v-btn
          >
        </v-card-title>
      </v-sheet>

      <v-card justify-center outlined class="px-5 my-5" v-if="!workOrder">
        <v-card-text>
          <v-col cols="6" offset="3" class="mt-6">
            <v-alert
              color="orange"
              border="left"
              elevation="2"
              colored-border
              type="warning"
              >N'oubliez de vérifier que
              <span class="font-weight-bold"
                >les heures de vols sont à jour</span
              >, auquel cas il convient de les ajouter dans l'onglet heure de
              vol de l'aéronef
              <span class="text-decoration-underline"
                >avant toute création d'OT.</span
              >
            </v-alert>
          </v-col>
          <v-col cols="4" offset="4" class="pt-12">
            <v-select
              outlined
              dense
              label="Que souhaitez réaliser ?"
              v-model="otChoice"
              :items="otChoiceList"
              required
            ></v-select>
          </v-col>
          <v-col cols="4" offset="4" v-if="otChoice == 'travaux'">
            <v-text-field
              outlined
              dense
              label="Précisez l'intitulé de l'OT"
              v-model="subOrderType"
              items="subOrderType"
              required
            ></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn
            bottom
            :to="{
              name: 'aeronef',
              params: {
                immatriculation: OTaircraft.immatriculation,
                page: 'ot',
              },
            }"
            >Annuler
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn bottom class="primary dark" @click="saveOT()">
            Commencer
          </v-btn>
        </v-card-actions>
      </v-card>

      <template v-if="workOrder">
        <v-stepper v-model="step" vertical>
          <v-stepper-step :complete="step > 1" step="1"
            >Informations de l'aéronef
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card color="background" outlined class="mb-8">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p class="text-subtitle-2">
                      Dernière heure de vol enregistré le
                      <!-- <span class="red--text" v-if="aircraft.flies.length > 0">{{ aircraft.lastFly.flyDate | moment("DD/MM/YYYY") }}</span> -->
                    </p>

                    <v-row>
                      <v-col cols="2">
                        <v-text-field
                          label="Cellule"
                          :value="showHours(OTaircraft.cell)"
                          filled
                          background-color="white"
                          outlined
                          dense
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-for="mot in OTaircraft.motors"
                          :key="mot.id"
                          label="Moteur"
                          :value="showHours(mot)"
                          background-color="white"
                          outlined
                          dense
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-for="prop in OTaircraft.propellers"
                          :key="prop.id"
                          label="Hélice"
                          :value="showHours(prop)"
                          background-color="white"
                          outlined
                          dense
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="2" class="py-0">
                        <v-text-field
                          v-model="reference"
                          outlined
                          background-color="white"
                          dense
                          label="Référence de l'Ordre de travail"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2" class="py-0">
                        <v-text-field
                          label="Gestionnaire de l'aéronef"
                          :value="workOrder.user.name"
                          background-color="white"
                          outlined
                          dense
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2" class="py-0">
                        <v-select
                          v-model="workshop"
                          label="Organisme de maintenance"
                          outlined
                          background-color="white"
                          dense
                          clearable
                          :items="
                            contacts
                              .map(function (c) {
                                return { text: c.name, value: c.id };
                              })
                              .sort((a, b) => a.text.localeCompare(b.text))
                          "
                        ></v-select>
                      </v-col>
                      <v-col cols="2" class="py-0">
                        <v-select
                          outlined
                          dense
                          background-color="white"
                          label="Sera-t-il facturé ?"
                          v-model="otBill"
                          :items="otBillList"
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-btn color="primary" @click="saveStep()">Sauvegarder </v-btn>
            <v-btn
              text
              class="ml-4"
              :to="{
                name: 'aeronef',
                params: {
                  immatriculation: OTaircraft.immatriculation,
                  page: 'ot',
                },
              }"
            >
              Annuler
            </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="step > 2"
            step="2"
            v-if="workOrder.orderType == 'visite'"
            >Visites protocolaires<small
              >Selectionnez les visites concernées
            </small>
          </v-stepper-step>

          <v-stepper-content
            step="2"
            v-if="workOrder.orderType == 'visite'"
          >
            <v-card color="background" outlined class="mb-8">
              <visite :OT="workOrder"></visite>
            </v-card>
            <v-btn color="primary" @click="saveStep()">Sauvegarder </v-btn>
            <v-btn text class="ml-4" @click="prev()"> Précedent </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="step > 3" step="3"
            >AD/SB répétitives<small
              >Selectionnez tous les AD/SB à joindre</small
            ></v-stepper-step
          >
          <v-stepper-content step="3">
            <v-card color="background" outlined class="mb-8 pa-2">
              <ad :OT="workOrder"></ad>
            </v-card>
            <v-btn color="primary" @click="saveStep()">Sauvegarder </v-btn>
            <v-btn text class="ml-4" @click="prev()"> Précedent </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="step > 4" step="4"
            >Items<small
              >Selectionnez tous les items à joindre</small
            ></v-stepper-step
          >
          <v-stepper-content step="4">
            <v-card color="background" outlined class="mb-8 pa-2">
              <piece :OT="workOrder"></piece>
            </v-card>
            <v-btn color="primary" @click="saveStep()">Sauvegarder </v-btn>
            <v-btn class="ml-4" text @click="prev()"> Précedent </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="step > 5" step="5"
            >Travaux/demandes supplémentaires<small
              >Ajouter les demandes complémentaires</small
            ></v-stepper-step
          >
          <v-stepper-content step="5">
            <v-card color="background" outlined class="mb-8 pa-2">
              <travaux :OT="workOrder"></travaux>
            </v-card>
            <v-btn class="primary" @click="saveStep()">Sauvegarder </v-btn>
            <v-btn class="ml-4" text @click="prev()"> Précedent </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="step > 6" step="6"
            >Documents à joindre<small
              >Selectionnez tous les documents à joindre</small
            >
          </v-stepper-step>

          <v-stepper-content step="6" height="500px">
            <v-card color="background" outlined class="mb-8 pa-2">
              <v-list flat>
                <v-list-item-group multiple>
                  <v-list-item
                    v-for="doc in workOrderDocuments"
                    :key="doc.id"
                    :value="doc.id"
                  >
                    <template v-slot:default="{}">
                      <v-list-item-action>
                        <v-checkbox
                          dense
                          :input-value="
                            workOrder.workOrderDocuments.find(
                              (d) => d['id'] == doc.id
                            )
                              ? true
                              : false
                          "
                          @click="changeDoc(doc.id)"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-subtitle>{{
                          doc.name
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
            <v-btn class="primary" @click="saveOT(true)">Finaliser </v-btn>
            <v-btn class="ml-4" text @click="prev()"> Précedent </v-btn>
          </v-stepper-content>
        </v-stepper>
      </template>
    </v-col>
  </v-container>
</template>

<style scoped>
</style>



<script>
import { workOrderQuery } from "../../graphql/work_order/work_order";
import { aircraftQuery } from "../../graphql/aircraft/aircraft";
import { createWorkOrderMutation } from "../../graphql/work_order/create_work_order";
import { updateWorkOrderMutation } from "../../graphql/work_order/update_work_order";
import { contactsQuery } from "../../graphql/contact/contacts";
import { workOrderDocumentsQuery } from "../../graphql/work_order/work_order_documents";
import { changeWorkOrderDocumentLinkMutation } from "../../graphql/work_order/change_work_order_document_link";
import { workOrderItemStopsQuery } from "../../graphql/work_order/work_order_item_stops";
import { itemStopsQuery } from "../../graphql/item_stop/item_stops";
import Travaux from "./Travaux.vue";
import Piece from "./Piece.vue";
import Visite from "./Visite.vue";
import Ad from "./Ad.vue";

export default {
  mixins: [],
  name: "NewPE",
  components: { Travaux, Visite, Ad, Piece },
  data() {
    return {
      OTId: null,
      otChoice: null,
      otChoiceTravaux: null,
      otChoiceList: [
        { text: "Visite", value: "visite" },
        { text: "Travaux", value: "travaux" },
        { text: "Dépannage", value: "depannage" },
        { text: "MB303/CEN", value: "mb303_cen" },
      ],
      otBill: true,
      otBillList: [
        { text: "OT Facturé", value: true },
        { text: "OT Offert", value: false },
      ],
      aircraftChoice: null,
      aircraftImmat: null,
      workOrder: null,
      contacts: [],
      workshop: null,
      step: 1,
      workOrderDocuments: [],
      reference: null,
      itemStops: null,
    };
  },
  methods: {
    saveOT(finish = false) {
      if (this.workOrder && this.workOrder.id) {
        this.$apollo
          .mutate({
            mutation: updateWorkOrderMutation,
            variables: {
              input: {
                id: parseInt(this.workOrder.id, 10),
                attributes: {
                  workshopId: this.workshop,
                  reference: this.reference,
                  generateInvoice: this.otBill,
                },
              },
            },
          })
          .then((data) => {
            console.log(data);
            if (finish) {
              this.$router.push({
                name: "ot",
                params: {
                  id: data.data.updateWorkOrder.workOrder.id,
                },
              });
            }
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createWorkOrderMutation,
            variables: {
              input: {
                attributes: {
                  aircraftId: this.OTaircraft.id,
                  orderType: this.otChoice,
                  subOrderType: this.subOrderType,
                },
              },
            },
          })
          .then((data) => {
            console.log(data);
            this.OTId = data.data.createWorkOrder.workOrder.id;
            if (finish) {
              this.$router.push({
                name: "ot",
                params: {
                  id: data.data.createWorkOrder.workOrder.id,
                },
              });
            } else {
              this.$router.push({
                name: "edit_ot",
                params: {
                  id: data.data.createWorkOrder.workOrder.id,
                },
              });
            }
          });
      }
    },
    changeDoc(docId) {
      this.$apollo
        .mutate({
          mutation: changeWorkOrderDocumentLinkMutation,
          variables: {
            input: {
              id: parseInt(this.workOrder.id, 10),
              docId: parseInt(docId, 10),
            },
          },
        })
        .then((data) => {
          console.log(data);
          this.$apollo.queries.workOrder.refetch();
        });
    },
    prev() {
      if (this.workOrder.orderType == "travaux" && this.step == 3) {
        this.step--;
        this.step--;
      } else {
        this.step--;
      }
    },
    next() {
      this.step++;
    },
    saveStep() {
      if (this.workOrder.orderType == "travaux" && this.step == 1) {
        this.saveOT();
        this.step++;
        this.step++;
      } else {
        this.saveOT();
        this.step++;
      }
    },
    showHours(aircraftPart) {
      if (aircraftPart.flyValue["current"]) {
        const flyValue = aircraftPart.flyValue["current"].find(
          (f) => f.unit_code == "hours"
        );
        var str = "";
        str += Math.floor(flyValue.value / 60);
        str += " h ";
        str += flyValue.value % 60;
        return str;
      } else {
        return "";
      }
    },
  },
  computed: {
    OTaircraft() {
      if (this.workOrder) {
        return this.workOrder.aircraft;
      } else {
        return this.aircraft;
      }
    },

    inspections() {
      console.log("item stops");
      console.log(this.itemStops);
      if (this.itemStops) {
        return this.itemStops.filter((i) => i.stop.inspection === true);
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.OTId = this.$route.params.id;
    this.aircraftImmat = this.$route.params.immatriculation;
  },
  apollo: {
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.aircraftImmat,
        };
      },
      skip() {
        return !this.aircraftImmat;
      },
      result() {},
    },
    contacts: {
      query: contactsQuery,
      variables: {
        type: ["Workshop", "Engineer"],
      },
    },
    workOrderDocuments: {
      query: workOrderDocumentsQuery,
    },
    workOrderItemStops: {
      query: workOrderItemStopsQuery,
      variables() {
        return {
          workOrderId: this.OTId,
        };
      },
      skip() {
        return !this.OTId;
      },
    },
    itemStops: {
      query: itemStopsQuery,
      variables() {
        return {
          aircraftId: parseInt(this.workOrder.aircraft.id, 10),
        };
      },
      skip() {
        return !this.workOrder;
      },
    },
    workOrder: {
      query: workOrderQuery,
      variables() {
        return {
          id: this.OTId,
        };
      },
      skip() {
        return !this.OTId;
      },
      result({ data }) {
        if (data) {
          console.log(data);
          this.otChoice = data.workOrder.orderType;
          this.otChoiceTravaux = data.workOrder.SuborderType;
          if (data.workOrder.workshop) {
            this.workshop = data.workOrder.workshop.id;
          }
          this.reference = data.workOrder.reference;
          this.otBill = data.workOrder.generateInvoice;
        }
      },
    },
  },
};
</script>