<template>
  <v-container fluid class="background">
    <v-col cols="6" offset="3">
      <v-sheet outlined >
        <v-card-title>
					<div>
						<div class="primary--text" >
							Template du 
							<span style="font-weight: bold; color: red">
								DR400-120
							</span>
						</div>
					</div>
        </v-card-title>
        <v-data-table
          :items="templates"
          :headers="header"
					hide-default-footer
          :items-per-page="all"
          :search="search"
          dense
					show-expand
          no-results-text="Aucun résultat"
        >
				
        </v-data-table>

      </v-sheet>
    </v-col>
  </v-container>
</template>

<style scoped>
</style>

<script>

export default {
  components: {
  },
  data() {
    return {
      header: [
      ],
      search: "",
      isLoading: true,
    };
  },

};
</script>