<template>
  <div>
    <v-container fluid>
      <v-row v-if="cen">
        <v-col cols="12">
          <v-sheet>
            <v-card-title class="text-h5">
              <v-btn
                icon
                fab
                outlined
                color="primary"
                x-small
                link
                active-class="false"
                :to="{ name: 'cens' }"
                class="ma-4"
              >
                <v-icon class="primary--text">mdi-arrow-left-thick</v-icon>
              </v-btn>

              <span>CEN |</span>
              <template v-if="cen.cell">
                <span style="font-size: 24px" class="ml-2" v-if="cen.action == 'extension-1'" >
                  1er extension du {{ cen.immatriculation }}
                </span>
                <span style="font-size: 24px" class="ml-2" v-if="cen.action == 'extension-2'" >
                  2ème extension du {{ cen.immatriculation }}
                </span>
                <span style="font-size: 24px" class="ml-2" v-if="cen.action == 'renew'" >
                  Renouvellement du {{ cen.immatriculation }}
                </span>
              </template>
                <v-chip
                  class="ma-2"
                  color="red lighten-2"
                  label
                  small
                  text-color="white"
                  v-if="cen.closedAt"
                >
                  <v-icon small left>mdi-archive-outline</v-icon>
                  Cloturé
                </v-chip>
                <v-alert
                  class="ml-12"
                  v-if="cen.totalOpenDeviations"
                  dense
                  text
                  border="left"
                  outlined
                  type="warning"
                  color="red"
                   >
                  <span>
                    {{ cen.totalOpenDeviations }} écart(s) encore ouvert(s)
                  </span>
                </v-alert>
              <v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn small fab icon outlined v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      link
                      :to="{
                        name: 'cen_edit',
                        params: { id: cen.id },
                      }"
                      v-if="!cen.closedAt"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Modifier les infos</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      :to="{
                        name: 'cen_review',
                        params: { id: cen.id },
                      }"
                      v-if="!cen.closedAt"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-airplane-edit</v-icon>
                      </v-list-item-icon>

                      <v-list-item-title>Réaliser le CEN</v-list-item-title>
                    </v-list-item >                                        
                      <v-list-item
                        link
                        v-if="cen.closedAt"
                        @click="openDialog()"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-arrow-expand-right</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Renouveller</v-list-item-title>
                       
                    </v-list-item>
                    <!-- <v-list-item>
                      <v-list-item-icon>
                        <v-icon>mdi-pen-plus</v-icon>
                      </v-list-item-icon>

                      <v-list-item-title>Ajouter une tâche</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item
                      link
                      @click="closeCen()"
                      v-if="!cen.closedAt"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-lock-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Clôturer</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      link
                      v-if="$permissions.can(me, 'admin') && cen.closedAt"
                      @click="uncloseCen()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-lock-open-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Déclôturer</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item v-if="!cen.closedAt" @click="deleteCen()">
                      <v-list-item-icon>
                        <v-icon color="red">mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="red--text"
                        >supprimer le CEN</v-list-item-title
                      >
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-card-title>

            <v-col cols="12">
              <v-row>
                <v-col :cols="colsize">
                  <v-card-title>
                    <v-row>
                      <v-col cols="2">
                        <v-icon>mdi-file-document-outline</v-icon>
                      </v-col>
                      <v-col align="left">ORGANISATION</v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-col cols="1">
                          <v-icon >mdi-folder-pound-outline</v-icon>
                        </v-col>
                        <v-col cols="4"><span class="font-weight-medium ml-2">Reference</span ></v-col>
                        <v-col cols="7" class="text-center">
                          <span class="primary--text">
                            {{ cen.reference }}
                          </span>

                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-airplane</v-icon>
                        </v-col>
                        <v-col cols="4"><span class="font-weight-medium ml-2">Aéronef</span ></v-col>
                        <v-col cols="7" class="text-center">
                          <span class="primary--text" v-if="cen.cell">
                            {{ cen.cell.tcHolder.name }}
                            {{ cen.cell.reference }}
                          </span>
                          <span class="nonrens" v-else>Donnée obligatoire</span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-card-account-details-outline</v-icon>
                        </v-col>
                        <v-col cols="4"><span class="font-weight-medium ml-2">Client</span ></v-col>
                        <v-col cols="7" class="pl-8 text-center">
                          <span class="primary--text" v-if="cen.client">
                            {{ cen.client.name }}
                          </span>
                          <span class="nonrens" v-else>Donnée obligatoire</span>
                        </v-col>

                        <template v-if="cen.contractType">
                          <v-col cols="1">
                            <v-icon>mdi-file-document-edit-outline</v-icon>
                          </v-col>
                          <v-col cols="4"><span class="font-weight-medium ml-2">Contrat</span ></v-col>
                          <v-col cols="7" class="text-center">
                            <span
                              class="primary--text"
                              v-if="cen.exploitationType"
                            >
                              {{ cen.exploitationType }}
                            </span>
                            <span class="primary--text" v-if="cen.contractType">
                              ({{ cen.contractType }})
                            </span>
                          </v-col>
                        </template>
                        <v-col cols="1">
                          <v-icon>mdi-account-edit-outline</v-icon>
                        </v-col>
                        <v-col cols="4"><span class="font-weight-medium ml-2">PEN</span ></v-col>
                        <v-col cols="7" class="text-center">
                          <span>
                            <span class="primary--text" v-if="cen.user">
                              {{ cen.user.lastName }} {{ cen.user.firstName }}
                            </span>
                            <span class="nonrens" v-else>
                              Donnée obligatoire</span
                            >
                          </span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-calendar</v-icon>
                        </v-col>
                        <v-col cols="4"><span class="font-weight-medium ml-2">Date </span ></v-col>
                        <v-col cols="7" class="text-center">
                          <span>
                            <span class="primary--text" v-if="cen.cenDate">
                              {{ cen.cenDate | moment("DD/MM/YYYY") }}
                            </span>
                            <span class="grey--text" v-else
                              >Date non déterminée</span
                            >
                          </span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-map-marker-outline</v-icon>
                        </v-col>
                        <v-col cols="4"><span class="font-weight-medium ml-2">Lieu </span ></v-col>
                        <v-col cols="7" class="text-center">
                          <span>
                            <span class="primary--text" v-if="cen.place">
                              {{ cen.place }}
                            </span>
                            <span class="grey--text" v-else
                              >Lieu non déterminée</span
                            >
                          </span>
                        </v-col>

                        <v-col cols="1">
                          <v-icon>mdi-card-account-phone-outline</v-icon>
                        </v-col>
                        <v-col cols="4"><span class="font-weight-medium ml-2">contact</span ></v-col>
                        <v-col cols="7" class="text-center">
                          <span
                            v-if="cen.contact"
                            v-html="cen.contact"
                            class="editor rens"
                          ></span>
                          <span class="grey--text" v-else
                            >Donnée non renseignée</span
                          >
                        </v-col>

                        <v-col cols="1">
                          <v-icon v-if="cen.signedReportAttachment"
                            >mdi-file-document-check-outline</v-icon
                          >
                          <v-icon v-else>mdi-file-document-edit-outline</v-icon>
                        </v-col>
                        <v-col cols="4"><span class="font-weight-medium ml-2">Rapport</span></v-col>
                        <v-col cols="7" class="pl-8 text-center">
                          <template v-if="showReport" >
                            <template v-if="cen.signedReportAttachment ">
                              <v-btn
                                color="red"
                                dark
                                outlined
                                small
                                target="_blank"
                                :href="cen.signedReportAttachment.fileUrl"
                                >Rapport Signé
                                <v-icon right dark
                                  >mdi-file-pdf-box-outline</v-icon
                                ></v-btn
                              >
                            </template>

                            <template v-else>
                              <v-row justify="center">
                                <v-dialog
                                  v-model="dialog_upload2"
                                  persistent
                                  hide-overlay
                                  max-width="600"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                    color="grey"
                                      small
                                      outlined
                                      v-on="on"
                                      @click="dialog_upload2 = !dialog_upload2"
                                    >
                                      ajouter
                                      <v-icon right dark>
                                        mdi-file-upload-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>

                                  <v-card>
                                    <v-card-title class="headline"
                                      >Ajout du rapport signé</v-card-title
                                    >
                                    <v-card-text class="pt-4">
                                      <v-container>
                                        <v-row>
                                          <v-col cols="12">
                                            <dashboard :uppy="uppyReport" />
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        class="mr-4 primary--text"
                                        outlined
                                        depressed
                                        @click="cleanDialog()"
                                      >
                                        Fermer</v-btn
                                      >
                                      <v-btn
                                        class="mr-4 primary"
                                        dark
                                        @click="updateSignedReport()"
                                        >Uploader</v-btn
                                      >
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-row>
                            </template>
                          </template>
                          <template v-else>
                            <span class="grey--text"
                              >{{ showReportErrors }}</span
                            >
                          </template>
                        </v-col>
                          <v-col cols="1">
                            <v-icon>mdi-alert</v-icon>
                          </v-col>
                          <v-col cols="4"><span class="font-weight-medium ml-2">Ecart(s)</span></v-col>
                          <v-col cols="7" class="pl-8 text-center" v-if="cen.totalDeviations > 0">
                            <span v-if="cen.totalDeviations > (cen.totalDeviations -  cen.totalOpenDeviations)">
                             <span class="red--text font-weight-bold">{{ cen.totalDeviations -  cen.totalOpenDeviations }}</span> / {{ cen.totalDeviations }}
                            </span>
                            <span v-else>{{ cen.totalDeviations -  cen.totalOpenDeviations }} / {{
                              cen.totalDeviations }} </span>
                          </v-col>
                          <v-col v-else cols="7" class="pl-8 text-center"> <span class="grey--text">Aucun(s) écart(s)</span></v-col>


                        <!-- <v-col cols="1">
                          <v-icon v-if="cen.signedCertificateAttachment"
                            >mdi-file-document-check-outline</v-icon
                          >
                          <v-icon v-else>mdi-file-document-edit-outline</v-icon>
                        </v-col>
                        <v-col cols="4">CEN</v-col>
                        <v-col cols="7" class="pl-8 text-center">
                          <template v-if="cen.signedCertificateAttachment">
                            <v-btn
                              color="red"
                              dark
                              outlined
                              target="_blank"
                              :href="cen.signedCertificateAttachment.fileUrl"
                              >CEN Signé
                              <v-icon right dark
                                >mdi-file-pdf-box-outline</v-icon
                              ></v-btn
                            >
                          </template>
                          <template v-else>
                            <v-row justify="center">
                              <v-dialog
                                v-model="dialog_upload"
                                persistent
                                hide-overlay
                                max-width="600"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="primary--text"
                                    dark
                                    x-small
                                    outlined
                                    v-on="on"
                                    @click="dialog_upload = !dialog_upload"
                                  >
                                    Uploader le CEN signé
                                    <v-icon right dark>
                                      mdi-cloud-upload
                                    </v-icon>
                                  </v-btn>
                                </template>

                                <v-card>
                                  <v-card-title class="headline"
                                    >Ajout du CEN signé</v-card-title
                                  >
                                  <v-card-text class="pt-4">
                                    <v-container>
                                      <v-row>
                                        <v-col cols="12">
                                          <dashboard :uppy="uppy" />
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      class="mr-4 primary--text"
                                      outlined
                                      depressed
                                      @click="cleanDialog()"
                                    >
                                      Fermer</v-btn
                                    >
                                    <v-btn
                                      class="mr-4 primary"
                                      dark
                                      @click="updateSignedCertificate()"
                                      >Uploader</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-row>
                          </template>
                        </v-col> -->
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col :cols="colsize">
                  <v-card-title>
                    <v-row>
                      <v-col cols="2">
                        <v-icon>mdi-airplane</v-icon>
                      </v-col>
                      <v-col align="left">INFORMATION</v-col>
                    </v-row>
                  </v-card-title>

                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <v-list>
                        <v-list-item>
                          <v-list-item-icon
                            ><v-icon>mdi-calendar</v-icon></v-list-item-icon
                          >
                          <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">
                              Expiration du CEN précédent</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              <span
                                class="primary--text"
                                v-if="cen.lastCenDate"
                                >{{ cen.lastCenDate | moment("DD/MM/YYYY") }}
                              </span>
                              <span class="nonrens" v-else>
                                Donnée obligatoire</span
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon
                            ><v-icon>mdi-tools</v-icon></v-list-item-icon
                          >
                          <v-list-item-content>
                            <v-list-item-title>Atelier</v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="primary--text" v-if="cen.workshop"
                                >{{ cen.workshop.name }}
                              </span>
                              <span class="grey--text" v-else>
                                Donnée à ajouter</span
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon
                            ><v-icon>mdi-parking</v-icon></v-list-item-icon
                          >
                          <v-list-item-content>
                            <v-list-item-title>Stationnement</v-list-item-title>
                            <v-list-item-subtitle>
                              <template v-if="cen.aircraft">
                                <span
                                  class="primary--text"
                                  v-if="cen.aircraft.parkingType"
                                >
                                  {{ cen.aircraft.parkingType }}
                                </span>
                                <span class="grey--text" v-else
                                  >Donnée à ajouter dans la fiche aéronef</span
                                >
                              </template>
                              <template v-else>
                                <span
                                  class="primary--text"
                                  v-if="cen.parkingType"
                                >
                                  {{ cen.parkingType }}
                                </span>
                                <span class="grey--text" v-else
                                  >Donnée à ajouter</span
                                >
                              </template>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon
                            ><v-icon>mdi-traffic-cone</v-icon></v-list-item-icon
                          >
                          <v-list-item-content v-if="cen.aircraft">
                            <v-list-item-title>Deviation</v-list-item-title>
                            <v-list-item-subtitle>
                              <span
                                class="primary--text"
                                v-if="cen.aircraft.aircraftDeviations.length > 0"
                              >
                                Oui
                              </span>
                              <span
                                class="primary--text"
                                v-else
                              >
                                Aucune déviation
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content v-else>
                            <v-list-item-title>Deviation</v-list-item-title>
                            <v-list-item-subtitle>
                              <span
                                class="primary--text"
                                v-if="cen.deviation"
                              >
                                Oui
                              </span>
                              <span
                                class="primary--text"
                                v-if="cen.deviation == false"
                              >
                                Aucune déviation
                              </span>
                              <span class="grey--text" v-if="cen.deviation == null"
                                >Donnée à ajouter</span
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon
                            ><v-icon
                              >mdi-folder-edit-outline</v-icon
                            ></v-list-item-icon
                          >
                          <v-list-item-content>
                            <v-list-item-title>Modifications</v-list-item-title>
                            <v-list-item-subtitle v-if="cen.aircraft">
                              <span
                                class="primary--text"
                                v-if="
                                  cen.aircraft.aircraftCustomizations.length > 0
                                "
                              >
                                {{ cen.aircraft.aircraftCustomizations.length }}
                                modification(s)
                              </span>
                              <span class="primary--text" v-else>
                                Aucune modification
                              </span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>
                              <span
                                class="primary--text"
                                v-if="cen.modifications"
                              >
                                {{ cen.modifications }} modification(s)
                              </span>
                              <span
                                class="primary--text"
                                v-if="cen.modifications == 0"
                              >
                                Aucune modification
                              </span>
                              <span
                                class="grey--text"
                                v-if="cen.modifications == null"
                                >Donnée à ajouter</span
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon
                            ><v-icon
                              >mdi-clipboard-edit-outline</v-icon
                            ></v-list-item-icon
                          >
                          <v-list-item-content v-if="cen.aircraft">
                            <v-list-item-title>MB303</v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="primary--text" v-if="
                                  cen.aircraft.aircraftMb303s.length > 0
                                ">
                                Oui
                              </span>
                              <span
                                class="primary--text"
                                v-else
                              >
                                Aucun MB303
                              </span>                              
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content v-else>
                            <v-list-item-title>MB303</v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="primary--text" v-if="cen.mb303">
                                Oui
                              </span>
                              <span
                                class="primary--text"
                                v-if="cen.mb303 == false"
                              >
                                Aucun MB303
                              </span>
                              <span class="grey--text" v-if="cen.mb303 == null">
                                Donnée à ajouter
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon
                            ><v-icon
                              >mdi-airplane-edit</v-icon
                            ></v-list-item-icon
                          >
                          <v-list-item-content v-if="cen.aircraft">
                            <v-list-item-title
                              >Rapport d'incident</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              <span
                                class="primary--text"
                                v-if="cen.aircraft.aircraftIncidents.length > 0"
                              >
                                {{ cen.aircraft.aircraftIncidents.length }} Rapport(s) d'incident
                              </span>
                              <span
                                class="primary--text"
                                v-else
                              >
                                Aucun Rapport
                              </span>                              
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content v-else>
                            <v-list-item-title
                              >Rapport d'incident</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              <span
                                class="primary--text"
                                v-if="cen.incidentReports >= 1"
                              >
                                {{ cen.incidentReports }} Rapport(s) d'incident
                              </span>
                              <span
                                class="primary--text"
                                v-if="cen.incidentReports == 0"
                              >
                                Aucun Rapport
                              </span>
                              <span
                                class="grey--text"
                                v-if="cen.incidentReports == null"
                                >Donnée à ajouter</span
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="colsize">
                  <v-card-title>
                    <v-row>
                      <v-col cols="2">
                        <v-icon>mdi-file-document-outline</v-icon>
                      </v-col>
                      <v-col align="left">PREPARATION</v-col>
                    </v-row>
                  </v-card-title>

                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <v-list
                        min-height="250px"
                        v-if="cen.cenDocuments.length > 0"
                      >
                        <v-list-item
                          v-for="doc in cen.cenDocuments"
                          :key="doc.id"
                        >
                          <v-list-item-icon
                            ><v-icon
                              >mdi-file-check-outline</v-icon
                            ></v-list-item-icon
                          >
                          <v-list-item-content v-if="doc.name">
                            <v-list-item-title
                              ><a :href="doc.url" target="_blank">{{
                                doc.name
                              }}</a></v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-list min-height="250px" v-else>
                        <v-list-item>
                          <v-list-item-title
                            ><span class="font-italic"
                              >aucun documents ajoutés</span
                            ></v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="12">
                      <v-card-title>
                        <v-row>
                          <v-col class="text-uppercase">BLOC-NOTES PEN</v-col>
                        </v-row>
                      </v-card-title>
                      <v-card min-height="150px" elevation="0">
                        <v-card-text class="pt-0">
                          <v-textarea
                            placeholder="Ajouter des informations utiles au PEN"
                            rows="4"
                            auto-grow
                            outlined
                            v-model="notes"
                          ></v-textarea>
                          <v-fab-transition>
                            <v-btn right absolute icon @click="saveNotes()">
                              <v-icon color="primary"
                                >mdi-content-save-outline</v-icon
                              >
                            </v-btn>
                          </v-fab-transition>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <DocumentPdf :colsize="colsize" />                  
              </v-row>
            </v-col>
          </v-sheet>
        </v-col>
      </v-row>
     <confirm ref="confirm"></confirm>
    </v-container>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-text class="pt-4">
          <div>
            <p class="text-h5 primary--text">Que souhaitez-vous faire ?</p>
            <v-row>
              <v-col cols="10" offset="1">
                <v-radio-group dense v-model="action" row>
                  <v-radio color="#1871ce" label="un renouvellement" value="renew"> </v-radio>
                  <v-radio color="#1871ce" label="Une extension" value="ext"> </v-radio
                ></v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDialog()"> Fermer </v-btn>
          <v-btn color="primary" text :loading="saveLoading" @click="extendCen()">
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.nonrens {
  font-style: italic;
  color: red;
}

.rens {
  font-style: italic;
  color: #2ebdc8;
}
</style>

<script>
import { updateCenMutation } from "../../graphql/cen/update_cen";
import { extendCenMutation } from "../../graphql/cen/extend_cen";
import { deleteCenMutation } from "../../graphql/cen/delete_cen";

import { cenQuery } from "../../graphql/cen/cen";
import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Uppy from "@uppy/core";
import confirm from "@/components/confirm.vue";
import { meQuery } from "@/graphql/me";
import DocumentPdf from './DocumentPdf.vue';

export default {
  name: "CEN",
  components: {
    Dashboard,
    confirm,
    DocumentPdf,
    // InfoGen,
    // UpdateCen,
    // CenFinalisation,
    // CenRealisation,

  },
  data() {
    return {
      value: 1,
      active: true,
      dialog_upload: false,
      dialog_upload2: false,
      dialog: false,
      modal: false,
      menu: false,
      uploadedFileID: null,
      uploadedFileReportID: null,
      notes: null,
      saveLoading: false,
      action: 'renew',
    };
  },
  computed: {
    page: {
      set(page) {
        this.$router.replace({ params: { ...this.$route.query, page } });
      },
      get() {
        return this.$route.params.page;
      },
    },
    btnsize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "x-small";
        case "sm":
          return "small";
      }
      return "normal";
    },
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: false,
        inline: true,
        width: "300px",
        height: "300px",
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          self.uploadedFileID = result.successful[0].response.signed_id;
        }),
    uppyReport: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: false,
        inline: true,
        width: "300px",
        height: "300px",
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          self.uploadedFileReportID = result.successful[0].response.signed_id;
        }),
    colsize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 6;
        case "lg":
          return 3;
        case "xl":
          return 3;
      }
      return "4";
    },
    titleClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "align-center subtitle-2";
        case "sm":
          return "align-center subtitle-2";
        case "md":
          return "align-center subtitle-2";
      }
      return "align-center h6";
    },
    showReport() {
      if (this.cen.totalOpenDeviations > 0 ) {
        return false;
      }
      if (!this.cen.reportAttachment) {
        return false;
      }
      return true;
    },
    showReportErrors() {
      if (this.cen.totalOpenDeviations > 0 ) {
        return "Les écarts doivent être clôturés";
      }
      if (!this.cen.reportAttachment) {
        return "Le rapport n'a pas encore été généré";
      }
      return "";
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
    updateSignedCertificate: function () {
      this.$apollo
        .mutate({
          mutation: updateCenMutation,
          variables: {
            input: {
              id: parseInt(this.cen.id, 10),
              attributes: {
                signedCertificate: this.uploadedFileID,
              },
            },
          },
        })
        .then(() => {
          this.$apollo.queries.cen.refetch();
        });
    },
    updateSignedReport: function () {
      this.$apollo
        .mutate({
          mutation: updateCenMutation,
          variables: {
            input: {
              id: parseInt(this.cen.id, 10),
              attributes: {
                signedReport: this.uploadedFileReportID,
              },
            },
          },
        })
        .then(() => {
          this.$apollo.queries.cen.refetch();
        });
    },
    saveNotes() {
      this.$apollo
        .mutate({
          mutation: updateCenMutation,
          variables: {
            input: {
              id: parseInt(this.cen.id, 10),
              attributes: {
                notes: this.notes,
              },
            },
          },
        })
        .then(() => {
          // this.$apollo.queries.cen.refetch();
        });
    },
    closeCen() {
      this.$refs.confirm
        .open("Clôturer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {      
            this.$apollo
              .mutate({
                mutation: updateCenMutation,
                variables: {
                  input: {
                    id: parseInt(this.cen.id, 10),
                    attributes: {
                      closedAt: new Date(),
                      status: "closed"
                    },
                  },
                },
              })
              .then(() => {
                // this.$apollo.queries.cen.refetch();
              });
          }
        });
      },    

    uncloseCen() {
      this.$apollo
        .mutate({
          mutation: updateCenMutation,
          variables: {
            input: {
              id: parseInt(this.cen.id, 10),
              attributes: {
                closedAt: null,
              },
            },
          },
        })
        .then(() => {
          // this.$apollo.queries.cen.refetch();
        });
    },
    cleanDialog() {
      this.dialog_upload = false;
      this.dialog_upload2 = false;
      this.uploadedFileID = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    extendCen() {
      this.saveLoading = true;
      
      this.$refs.confirm
        .open("Renouveller / Prolonger", "Êtes-vous sûr ?", {
          color: "red lighten-1",
        })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: extendCenMutation,
                variables: {
                  input: {
                    id: parseInt(this.cen.id, 10),
                    action:this.action,
                  },
                },
              })
              .then(({ data }) => {
                if (data) {     
                  this.closeDialog(),             
                  this.$router.push({
                    name: "cen",
                    params: { id: data.extendCen.cen.id },
                  });
                }
              });
          }
        });
    },
    deleteCen() {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteCenMutation,
                variables: {
                  input: {
                    id: parseInt(this.cen.id, 10),
                  },
                },                
              })
              .then(() => {
                 this.$router.push({
                   name: "cens",
                 });
              });
          }
        });
    },    
  },
  apollo: {
    me: {
      query: meQuery,
    },
    cen: {
      query: cenQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        if (data) {
          this.notes = data.cen.notes;
        }
        this.isLoading = false;
      },
    },
  },
};
</script>