var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.me && _vm.$permissions.can(_vm.me, 'admin'))?_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function({}){return [_c('v-btn',{staticClass:"btn ml-4",attrs:{"depressed":"","x-small":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("oubli liaison")])]}}],null,false,4275777500),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Création d'une liaison entre 2 documents")]),_c('v-card-text',{},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"8","offset":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.documents
                  .map(function (t) {
                    return {
                      text:
                        (t.tcHolders.length > 0 ? t.tcHolders[0].name : '') +
                        ' - ' +
                        t.numberRev,
                      value: t.id,
                    };
                  })
                  .sort((a, b) => a.text.localeCompare(b.text)),"label":"doc annulé","outlined":"","dense":""},model:{value:(_vm.cible),callback:function ($$v) {_vm.cible=$$v},expression:"cible"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","offset":"4"}},[_c('span',{staticClass:"text-h6"},[_vm._v("Annulé et remplacé par")])])],1),_c('v-row',[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"8","offset":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.documents
                  .map(function (t) {
                    return {
                      text:
                        (t.tcHolders.length > 0 ? t.tcHolders[0].name : '') +
                        ' - ' +
                        t.numberRev,
                      value: t.id,
                    };
                  })
                  .sort((a, b) => a.text.localeCompare(b.text)),"label":"remplacé par","outlined":"","dense":""},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"primary--text mb-4 mr-4",attrs:{"outlined":"","depressed":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Annuler ")]),_c('v-btn',{staticClass:"mr-4 btn mb-4",on:{"click":function($event){return _vm.save()}}},[_vm._v("Lier")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }