import gql from 'graphql-tag';

export const maintenanceProgrammesQuery = gql`
  query($status: String, , $year: String, $immatriculation: String) {
    maintenanceProgrammes(status: $status, year: $year, immatriculation: $immatriculation) {
      closedAt
      createdAt
      exportedAt
      id
      reference
      registrant
      status
      updatedAt
      programmeFile
      signedProgramme
      signedAt
      expiredAt
      manuals
      annexDData
      owners
      validatedAt
      pilotOwner
      createdBy {
        id
        firstName
        lastName
      }
      aircraft {
        id
        immatriculation
        contact {
          id
          name
        }
        contract {
            id
            register
          }
        cell {
          id
          serialNumber
          partModel {
            id
            reference
            fullName
            tcHolder {
              id
              name
            }
          }
        }
      }
  }
}`
