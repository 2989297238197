<template>
  <v-container fluid class="background">
  <v-col cols="8" offset="2">

    <v-sheet outlined style="padding-bottom: 30px">
      <v-form>
				<v-card-title class="headline text-uppercase">
					<div>
						<div class="primary--text" >
							Créer un nouveau template pour
							<span style="font-weight: bold; color: red">
								
							</span>
						</div>
					</div>
				</v-card-title>
				<v-divider style="padding-bottom: 50px"></v-divider>
      
				<v-row>
					<v-col cols="6" offset="3">
						<v-autocomplete
							v-model="partModel"

							label="Modèle de cellule"
							required
							outlined
							dense
						></v-autocomplete>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" align="center" class="pt-8">
						<v-btn
							class="mr-4 btn--text"
							outlined
							depressed
							to="/admin?tab=template"			
							>Annuler</v-btn
						>
						<v-btn
							class="mr-4 primary primary-text"
							dark
							@click="create()"
							>Continuer</v-btn
						>
						<!-- <v-btn class="mr-4 primary primary-text" dark v-else @click="create()"
							>Créer</v-btn
						> -->
					</v-col>
				</v-row>
      </v-form>
    </v-sheet>
	</v-col>
  </v-container>
</template>

<script>
// import { createTemplateMutation } from "@/graphql/template/create_template";
// import { templateQuery } from "@/graphql/template/templates";

export default {
  name: "NewTemplate",
  components: {

  },
  data() {
    return {
			partModel: "",
      partModels: [],
    };
  },
  // apollo: {
  //   templates: {
  //     query: templateQuery,
  //   },
	//     partModels: {
  //     query: partModelsQuery,
  //     variables: {
  //       serie: false,
  //       type: ["Cell"],
  //     },
  //   },
  // },
//  methods: {
//    create() {
// 		if (this.aircraftPart) {
// 		this.$apollo
// 			.mutate({
// 				mutation: updateTemplatePartMutation,
// 				variables: {
// 					input: {
// 						id: parseInt(this.aircraftPart.id, 10),
// 						attributes: {
// 							partModelId: this.partModelId,
// 						},
// 					},
// 				},
// 			})
// 			.then(() => {
// 				this.$router.push({
// 					name: "aeronef",
// 					params: {
// 						immatriculation: this.aircraft.immatriculation,
// 						page: "pieces",
// 					},
// 				});
// 			});
//       } else {
//         this.$apollo
//           .mutate({
//             mutation: createTemplateMutation,
//             variables: {
//               input: {
//                 attributes: {
//                   partModelId: this.partModelId,
//                 },
//               },
//             },
//           })
//           .then(() => {
//             this.$router.push({
//               name: "aeronef",
//               params: {
//                 immatriculation: this.aircraft.immatriculation,
//                 page: "pieces",
//               },
//             });
//           });
//     	}
//  		},
// 	},
};
</script>