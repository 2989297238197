<template>
  <v-form >
    <v-row>
      <v-col cols="4" offset="4" class="pb-4">
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="aprsDatePicker"
              label="Insérer la date de l'APRS"
              prepend-icon="mdi-calendar-month"
              hint="*Obligatoire"
              persistent-hint      
              outlined
              dense
              clearable
              :success="!!aprsDatePicker"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="aprsDate"
            :first-day-of-week="1"
            locale="fr"
            color="green"
            no-title
            @input="menuDate = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" offset="4">
        <v-select
          v-model="workshop"
          label="Organisme de maintenance"
          hint="*Obligatoire"
          persistent-hint      
          prepend-icon="mdi-tools"
          outlined
          dense
          :items="
            contacts.map(function (c) {
              return {
                text: c.name,
                value: c.id,
              };
            }).sort((a, b) => a.text.localeCompare(b.text))
          "
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center" class="mt-8">
        <v-btn class="mr-4 primary" dark @click="saveOT()"
          >Valider</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { updateWorkOrderMutation } from "../../graphql/work_order/update_work_order";
import { contactsQuery } from "../../graphql/contact/contacts";

export default {
  name: "AdministratifComponent",
  components: {},
  props: ["OT"],
  data() {
    return {
      menuDate: false,
      aprsDate: null,
      workshop: null,
      contacts: [],
    };
  },
  methods: {
    saveOT() {
      this.$apollo.mutate({
        mutation: updateWorkOrderMutation,
        variables: {
          input: {
            id: parseInt(this.OT.id, 10),
            attributes: {
              aprsDate: this.aprsDate,
              workshopId: this.workshop,
            },
          },
        },
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    aprsDatePicker() {
      return this.formatDate(this.aprsDate);
    },
  },
  created() {
    this.aprsDate = this.OT.aprsDate;
    if (this.OT.workshop) {
      this.workshop = this.OT.workshop.id;
    }
  },
  apollo: {
    contacts: {
      query: contactsQuery,
      variables: {
        type: ["Workshop", "Engineer"],
      },
    },
  },
};
</script>