<template>
  <v-sheet outlined class="pb-8">
    <v-row>
      <v-col cols="12">
        <v-card-title>
          <v-row>
            <v-col cols="4">
              <v-switch
                inset
                label="afficher tous les manuels"
                v-model="all"
              ></v-switch>
            </v-col>
            <v-col cols="2" offset="1">
              <v-select
                label="Quelle partie"
                :items="partOptions"
                v-model="partOption"
              ></v-select>
            </v-col>
            <!-- <v-col cols="2">
              <v-select
                label="Quel manuel"
                :items="
                  manualType.map(function (t) {
                    return { text: t.name, value: t.id };
                  })
                "
                v-model="manualType"
              ></v-select>
            </v-col> -->
            <v-col cols="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="filteredManual"
          :items-per-page="-1"
          hide-default-footer
          :search="search"
          :loading="isLoading"
          loading-text="Chargement... merci de patienter"
          dense
          no-results-text="Aucun résultat"
          :sort-by="['tcHolders[0].name', 'number']"
          :sort-desc="[false, true]"
        >
          <template v-slot:item.number="{ item }">
            <v-btn
              outlined
              target="_blank"
              small
              :to="{ name: 'document', params: { id: item.id } }"
              >{{ item.number }}</v-btn
            >
          </template>
          <template v-slot:item.lastCheckAt="{ item }">
            <v-chip outlined small :color="linkColor(item)">
              {{
                item.lastCheckAt
                  | moment("calendar", null, {
                    sameDay: "[Aujourd'hui]",
                    lastDay: "[Hier]",
                    lastWeek: "dddd [dernier]",
                    sameElse: "DD MMMM",
                  })
              }}</v-chip
            ></template
          >
          <template v-slot:item.check="{ item }">
            <v-btn icon @click="updateLastCheck(item)">
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.immatriculation="{ item }">
            <span v-if="item.aircraftParts.length > 0">
              <span
                v-for="aircraftPart in item.aircraftParts"
                :key="aircraftPart.id"
              >
                <v-btn
                  v-if="aircraftPart.aircraft.status != 'archive'"
                  text
                  x-small
                  :to="{
                    name: 'aeronef',
                    params: {
                      immatriculation: aircraftPart.aircraft.immatriculation,
                      page: 'info',
                    },
                  }"
                >
                  {{ aircraftPart.aircraft.immatriculation }}</v-btn
                >
              </span>
            </span>
          </template>
          <template v-slot:item.documentFile="{ item }">
            <v-icon v-if="item.documentFile" color="red"
              >mdi-file-pdf-box</v-icon
            >
            <v-icon v-else class="grey--text">mdi-block-helper</v-icon>
          </template>
          <template v-slot:item.title="{ item }">
            <span class="text-caption"> {{ item.title }}</span>
          </template>
          <template v-slot:item.version="{ item }">
            <span class="text-caption">{{ item.version }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { documentsQuerySuivi } from "../../graphql/document/documents_suivi";
import { updateDocumentMutation } from "../../graphql/document/update_document";
import { DateTime } from "luxon";

export default {
  name: "dataTableSuivi",
  data() {
    return {
      search: "",
      isLoading: true,
      manuelType: [],
      partOption: "not_part",
      all: false,
      documents: [],
      partOptions: [
        { text: "Tous (sauf pièce)", value: "not_part" },
        { text: "Cellule", value: "cell" },
        { text: "Moteur", value: "motor" },
        { text: "Helice", value: "propeller" },
        { text: "Pièce", value: "part" },
      ],
      headers: [
        {
          text: "",
          value: "check",
          width: "50px",
          groupable: false,
        },
        {
          text: "Date",
          value: "lastCheckAt",
          width: "150px",
          groupable: false,
        },
        { text: "Type", value: "manualType", width: "50px" },
        { text: "pdf", value: "documentFile", width: "50px" },

        {
          text: "TcHolder",
          value: "tcHolders[0].name",
        },
        { text: "Numero", value: "number", align: "start", width: "90px" },
        {
          text: "Titre",
          value: "title",
        },
        { text: "Edition", value: "version", width: "450px" },
        {
          text: "Immat concernée",
          value: "immatriculation",
        },
        // { text: "Note", value: "" },
      ],
    };
  },
  computed: {
    filteredManual() {
      if (this.all) {
        return this.documents;
      } else {
        return this.documents.filter((d) => d.aircraftParts.filter((p) => p.aircraft.status != 'archive' ).length > 0);
      }
    },
  },
  methods: {
    updateLastCheck(item) {
      this.$apollo
        .mutate({
          mutation: updateDocumentMutation,
          variables: {
            input: {
              id: parseInt(item.id, 10),
              attributes: {
                lastCheckAt: new Date().toISOString(),
              },
            },
          },
        })
        .then((data) => {
          console.log(data);
          // this.$apollo.queries.tcHolders.refetch();
        });
    },
    linkColor(item) {
      if (item.lastCheckAt) {
        const diffDate = DateTime.now()
          .diff(DateTime.fromISO(item.lastCheckAt), "days")
          .toObject();
        if (diffDate["days"] < 6) {
          return "green";
        }
      }
      return "accent";
    },
  },
  apollo: {
    documents: {
      query: documentsQuerySuivi,
      variables() {
        return {
          type: "Manual",
          status: "active",
          partType: this.partOption,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>