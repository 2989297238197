<template>
  <div>
    <v-container fluid>
      <v-row v-if="workOrder">
        <v-col cols="12" class="my-0">
          <v-sheet outlined>
            <v-card-title class="text-h5 tertio--text py-2">
              <v-btn
                icon
                fab
                outlined
                color="primary"
                x-small
                link
                active-class="false"
                class="ma-4"
                :to="{
                  name: 'ot',
                  params: {
                    id: workOrder.id,
                  },
                }"
              >
                <v-icon class="primary--text">mdi-arrow-left-thick</v-icon>
              </v-btn>
              <strong>OT | </strong>
              <span style="font-size: 24px" class="ml-2"
                >Retour de l'ordre de travail {{ workOrder.reference }}
              </span>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                small
                color="primary"
                x-small>Valider le retour
              </v-btn>
            </v-card-title>
            <!-- tabs -->
              <v-col cols="12">
                <v-tabs 
                  v-model="tab"
                  color="titre"
                  centered
                  center-active
                  fixed-tabs
                  show-arrows
                  icons-and-text
                  height="80px"
                  >
                  <v-tabs-slider color="titre"></v-tabs-slider>
                  <v-tab href="#info">
                    Information
                    <v-icon>mdi-information-outline</v-icon>
                  </v-tab>
                  <v-tab href="#visite"  v-if="workOrder.aprsDate">
                    Visite
                    <v-badge
                      color="accent"
                      v-if="nullVisiteValues"
                      :content="nullVisiteValues"
                      
                      ><v-icon>mdi-progress-wrench</v-icon>
                    </v-badge>
                  </v-tab>
                  <v-tab href="#butees_doc"  v-if="workOrder.aprsDate">
                    AD/SB
                    <v-badge
                      color="accent"
                      v-if="nullButeesDocValues"
                      :content="nullButeesDocValues"
                      ><v-icon>mdi-book-open-variant</v-icon>
                    </v-badge>
                    <!-- <v-icon left v-else>mdi-clipboard-list-outline</v-icon>  -->
                  </v-tab>
                  <v-tab href="#butees"  v-if="workOrder.aprsDate">
                    BUTEE
                    <v-badge
                      color="accent"
                      v-if="nullButeesValues"
                      :content="nullButeesValues"

                      ><v-icon>mdi-wrench-clock</v-icon>
                    </v-badge>
                  </v-tab>
                  <v-tab href="#travaux"  v-if="workOrder.aprsDate">
                    TRAVAUX SUPP
                    <v-badge
                      color="accent"
                      v-if="nullTravauxValues"
                      :content="nullTravauxValues"

                      ><v-icon>mdi-clipboard-list-outline</v-icon>
                    </v-badge>
                  </v-tab>
                  <v-tab href="#mechanic" v-if="workOrder.aprsDate">
                    MECANO ET OUTILS
                    <v-icon>mdi-tools</v-icon>
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="12">
                <v-tabs-items v-model="tab">                  
                  <v-tab-item value="info">
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-administratif
                          :OT="workOrder"
                        ></retour-administratif>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="visite">
                    <v-card-title class="d-flex d-md-none ml-4">
                    </v-card-title>
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-butee :OT="workOrder" typeStop="visite"></retour-butee>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="butees_doc">
                    <v-card-title class="d-flex d-md-none ml-4">
                    </v-card-title>
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-butee :OT="workOrder" typeStop="document_processing"></retour-butee>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="butees">
                    <v-card-title class="d-flex d-md-none ml-4">
                    </v-card-title>
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-butee :OT="workOrder" typeStop="aircraft_part"></retour-butee>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="travaux">
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-travaux :OT="workOrder"></retour-travaux>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="mechanic">
                    <v-card flat min-height="700px">
                      <v-card-text>
                        <retour-mechanic
                          :OT="workOrder"
                        ></retour-mechanic>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>

            <div v-if="$apollo.loading" class="text-center">
              <v-progress-circular
                :size="70"
                :width="7"
                color="blue-grey"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>


</style>
<script>
import { workOrderQuery } from "../../graphql/work_order/work_order";
import { workOrderItemsQuery } from "../../graphql/work_order/work_order_items";
import RetourAdministratif from "./RetourAdministratif.vue";
import RetourTravaux from "./RetourTravaux.vue";
import RetourButee from "./RetourButee.vue";
import { workOrderItemStopsQuery } from "../../graphql/work_order/work_order_item_stops";
import RetourMechanic from './RetourMechanic.vue';


export default {
  mixins: [],
  name: "Retour",
  components: { RetourAdministratif, RetourTravaux, RetourButee, RetourMechanic },
  data() {
    return {
      workOrder: null,
      fab: false,
      workOrderItems: [],
      workOrderItemStops: [],
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
    nullTravauxValues: function () {
      if (this.workOrderItems.length == 0) {
        return null;
      }

      var i = 0;
      this.workOrderItems.forEach((item) => {
        if (item.status == "new") {
          i += 1;
        }
      });
      return i;
    },
    nullVisiteValues: function () {
      if (this.workOrderItemStops.length == 0) {
        return null;
      }

      var i = 0;
      this.workOrderItemStops.forEach((item) => {
        if (item.status == "new" && item.itemStop.stop.inspection == true) {
          i += 1;
        }
      });
      return i;
    },
    nullButeesDocValues: function () {
      if (this.workOrderItemStops.length == 0) {
        return null;
      }

      var i = 0;
      this.workOrderItemStops.forEach((item) => {
        if (item.status == "new" && item.itemStop.documentProcessing !== null) {
          i += 1;
        }
      });
      return i;
    },
    nullButeesValues: function () {
      if (this.workOrderItemStops.length == 0) {
        return null;
      }

      var i = 0;
      this.workOrderItemStops.forEach((item) => {
        if (item.status == "new" && item.itemStop.aircraftPart !== null && item.itemStop.stop.inspection == false) {
          i += 1;
        }
      });
      return i;
    },
  },
  apollo: {
    workOrder: {
      query: workOrderQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    workOrderItems: {
      query: workOrderItemsQuery,
      variables() {
        return {
          workOrderId: parseInt(this.$route.params.id, 10),
        };
      },
    },
    workOrderItemStops: {
      query: workOrderItemStopsQuery,
      variables() {
        return {
          workOrderId: parseInt(this.$route.params.id, 10),
        };
      },
    },
  },
};
</script>  