<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    v-if="me && $permissions.can(me, 'admin')"
  >
    <template v-slot:activator="{}">
      <v-btn class="btn ml-4" depressed x-small @click="dialog = true"
        >oubli liaison</v-btn
      >
    </template>
    <v-card>
      <v-card-title
        >Création d'une liaison entre 2 documents</v-card-title
      >
      <v-card-text class="">
        <v-container>
          <v-row>
            <v-col cols="8" offset="2" class="mt-4">
              <v-autocomplete
                v-model="cible"
                :items="
                  documents
                    .map(function (t) {
                      return {
                        text:
                          (t.tcHolders.length > 0 ? t.tcHolders[0].name : '') +
                          ' - ' +
                          t.numberRev,
                        value: t.id,
                      };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text))
                "
                label="doc annulé"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" offset="4">
              <span class="text-h6">Annulé et remplacé par</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" offset="2" class="mt-4">
              <v-autocomplete
                v-model="source"
                :items="
                  documents
                    .map(function (t) {
                      return {
                        text:
                          (t.tcHolders.length > 0 ? t.tcHolders[0].name : '') +
                          ' - ' +
                          t.numberRev,
                        value: t.id,
                      };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text))
                "
                label="remplacé par"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-col align="center">
          <v-btn outlined
            depressed
            class="primary--text mb-4 mr-4" @click="cancel()"> Annuler </v-btn>
          <v-btn class="mr-4 btn mb-4" @click="save()">Lier</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { documentsSbLinkQuery } from "../../graphql/document/documents_sb_link";
import { createDocumentLinkMutation } from "../../graphql/document/create_document_link";
import { meQuery } from "../../graphql/me";

export default {
  name: "LiaisonModal",
  components: {},
  data() {
    return {
      dialog: false,
      source: null,
      cible: null,
      typeLink: "replace",
      documents: [],
      me: null,
    };
  },
  methods: {
    cleanDialog() {
      this.dialog = false;
      this.source = null;
      this.cible = null;
      this.typeLink = "replace";
    },
    save() {
      this.$apollo
        .mutate({
          mutation: createDocumentLinkMutation,
          variables: {
            input: {
              sourceId: this.source,
              targetId: this.cible,
              relation: this.typeLink,
            },
          },
        })
        .then(() => {
          this.cleanDialog();
        });
    },
    cancel() {
      this.cleanDialog();
    },
  },
  apollo: {
    documents: {
      query: documentsSbLinkQuery,
    },
    me: {
      query: meQuery,
    },
  },
};
</script>

