<template>
  <v-sheet outlined v-if="tcHolder">
    <v-card-title class="headline primary--text text-uppercase"
      >Bibliothèque - {{ tcHolder.name }}
    </v-card-title>
    <v-divider></v-divider>

    <v-card-title>
      <v-btn
        depressed
        class="primary--text"
        outlined
        :to="{ name: 'new_document' }"
        ><v-icon left dark> mdi-plus </v-icon> Nouveau document</v-btn
      >

      <v-spacer></v-spacer>
      <v-row>
        <v-col cols="3" offset="2">
          <v-select
            label="Type"
            :items="typeOptions"
            v-model="typeFilter"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            label="Statut"
            :items="statusOptions"
            v-model="statusFilter"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="documents"
      :items-per-page="15"
      :footer-props="{
        'items-per-page-options': [15, 50, 100],
        'items-per-page-text': 'lignes par page',
        'items-per-page-all-text': 'Tous',
      }"
      :sort-by="['createdAt']"
      :sort-desc="[true]"
      :search="search"
      :loading="isLoading"
      loading-text="Chargement... merci de patienter"
      dense
      no-results-text="Aucun résultat"
    >
      <template v-slot:item.documentFile="{ item }">
        <v-btn
          icon
          v-if="item.documentFile"
          :href="item.documentFile"
          target="_blank"
        >
          <v-icon color="red">mdi-file-pdf-box</v-icon>
        </v-btn>
        <v-btn icon v-else>
          <v-icon class="primary--text">mdi-block-helper</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.status="{ item }">
        <v-icon color="green" v-if="item.status == 'active'"
          >mdi-file-check-outline</v-icon
        >
        <v-icon v-if="item.status == 'replace'" color="orange"
          >mdi-file-swap-outline</v-icon
        >
        <v-icon color="red" v-if="item.status == 'cancel'"
          >mdi-file-remove-outline</v-icon
        >
      </template>

      <template v-slot:item.type="{ item }">
        <span v-if="item.type == 'Ad'">AD</span>
        <span v-if="item.type == 'Sb'">SB</span>
        <span v-if="item.type == 'Manual'">MANUEL</span>
      </template>

      <template v-slot:item.manualType="{ item }">
        <span v-if="item.manualType == 'MM'">Maintenance Manual</span>
        <span v-if="item.manualType == 'IPC'">Parts Catalogs</span>
        <span v-if="item.manualType == 'MV'">Manuel de Vol</span>
        <span v-if="item.manualType == 'IND'">Index</span>
        <span v-if="item.manualType == 'MISC'">Autres manuels</span>
      </template>

      <template v-slot:item.publishingDate="{ item }">{{
        item.publishingDate | moment("DD/MM/YYYY")
      }}</template>

      <template v-slot:item.number="{ item }">
          <v-btn v-if="item.type == 'Manual'" text small target="blank" :to="{ name: 'document', params: { id: item.id } }">{{ item.number }}</v-btn>
          <v-btn v-if="item.type == 'Ad' || item.type == 'Sb'" text small target="blank" :to="{ name: 'document', params: { id: item.id } }">{{ item.numberRev }}</v-btn>
      </template>


      <template v-slot:item.title="{ item }">
        <div class="wrap_text" style="min-width: 400px">{{ item.title }}</div>
      </template>

      <!-- <template v-slot:item.actions="{ item }">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
              :to="{ name: 'document', params: { id: item.id } }"
            >
              <v-list-item-icon>
                <v-icon>mdi-eye-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Voir</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              :to="{ name: 'document_edit', params: { id: item.id } }"
            >
              <v-list-item-icon>
                <v-icon>mdi-wrench-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Modifier</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              :to="{ name: 'new_document', query: { replace: item.id } }"
            >
              <v-list-item-icon>
                <v-icon>mdi-file-replace</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Annuler et remplacer</v-list-item-title>
            </v-list-item>
            <v-list-item @click="cancelDocument(item)">
              <v-list-item-icon>
                <v-icon>mdi-file-cancel-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Annuler</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template> -->

    </v-data-table>
  </v-sheet>
</template>

<style scoped>
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
.v-application a:hover {
  background-color: #b0bec5;
}
</style>

<script>
import { tcHolderQuery } from "../../graphql/tc_holder";
import { documentsQuery } from "../../graphql/document/documents";
import { deleteDocumentMutation } from "@/graphql/document/delete_document";
import { cancelDocumentMutation } from "@/graphql/document/cancel_document";

export default {
  name: "dataTableTcHolder",
  computed: {
    headers: function () {
      if (this.typeFilter == "Manual") {
        return [
          { text: "PDF", value: "documentFile", width: "50px" },
          { text: "TYPE", value: "manualType", width: "170px" },
          { text: "STATUS", value: "status", width: "70px" },
          {
            text: "NUMERO",
            align: "start",
            sortable: true,
            value: "number",
            width: "100px",
          },
          { text: "TITRE", value: "title", sortable: true, width: "30px" },
          { text: "EDITION", value: "version", width: "400px" },
          // { text: "", value: "actions", width: "50px", sortable: false },
        ];
      }
        return [
          {
            text: "PDF",
            value: "documentFile",
            width: "50px",
            align: "center",
          },
          { text: "STATUS", value: "status", width: "70px" },
          { text: "CAT.", value: "type", width: "60px" },
          { text: "NUMERO", align: "start", value: "number", width: "150px" },
          { text: "TITRE", value: "title", width: "500px" },
          // { text: "", value: "actions", sortable: false, width: "50px" },
        ];
    },
  },
  data() {
    return {
      documents: [],
      search: "",
      typeFilter: null,
      typeOptions: [
        { text: "Tous", value: null },
        { text: "AD", value: "Ad" },
        { text: "SB", value: "Sb" },
        { text: "Manuel", value: "Manual" },
      ],
      statusFilter: "active",
      statusOptions: [
        { text: "Tous", value: null },
        { text: "En cours", value: "active" },
      ],
    };
  },
  apollo: {
    documents: {
      query: documentsQuery,
      variables() {
        return {
          type: [this.typeFilter],
          status: this.statusFilter,
          tcHolder: [this.$route.params.id],
        };
      },
      result() {
        this.isLoading = false;
      },
    },
    tcHolder: {
      query: tcHolderQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }) {
        console.log(data);
        if (data) {
          this.observation = data.tcHolder.observation;
        }
      },
    },
  },
  methods: {
    deleteDocument(document) {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteDocumentMutation,
                variables: {
                  input: {
                    id: parseInt(document.id, 10),
                  },
                },
              })
              .then((data) => {
                console.log(data);
                this.$apollo.queries.documents.refetch();
              });
          }
        });
    },
    cancelDocument(document) {
      this.$refs.confirm
        .open("Annuler", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: cancelDocumentMutation,
                variables: {
                  input: {
                    id: parseInt(document.id, 10),
                  },
                },
              })
              .then((data) => {
                console.log(data);
                this.$apollo.queries.documents.refetch();
              });
          }
        });
    },
  },
};
</script>
