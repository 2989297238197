import gql from 'graphql-tag';


export const createDocumentLinkMutation = gql`
  mutation createDocumentLink($input: CreateDocumentLinkInput!) {
    createDocumentLink(input: $input) {
      documentLink {
        id
      }
    }
  }`
