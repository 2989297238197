<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-breadcrumbs
            :items="breadItems"
            divider=">"
            large
            class="mt-8, mb-6, px-4, py-2 text-uppercase primary--text"
          >
          </v-breadcrumbs>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-btn class="btn"
              depressed :to="{ name: 'new_pe' }"
              ><v-icon left dark> mdi-plus </v-icon>Nouveau
              Programme</v-btn
            >
            <v-spacer></v-spacer>
            <v-row>
              <v-col cols="3" offset="3">
                <v-select
                  label="Statut"
                  :items="listingOptions"
                  v-model="listingFilter"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  label="par date"
                  :items="dateOptions"
                  v-model="dateFilter"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="maintenanceProgrammes"
            :footer-props="{
              'items-per-page-options': [15, 50, 100, -1],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
            :search="search"
            :loading="isLoading"
            loading-text="On teste votre patience..."
            locale="fr-FR"
            dense
            no-data-text="Il n'y a encore aucunes données"
            no-results-text="Aucun résultat"                                                                                                                                                       
          >
            <template v-slot:item.type="{ item }">
              <span v-if="item.aircraft && item.aircraft.contract && item.aircraft.contract.register == true"> D </span>
              <span v-else> C </span>
            </template>

            <template v-slot:item.createdAt="{ item }">{{
              item.createdAt | moment("DD/MM/YYYY")
            }}</template>

            <template v-slot:item.closedAt="{ item }">{{
              item.closedAt | moment("DD/MM/YYYY")
            }}</template>

            <template v-slot:item.status="{ item }">
              <span v-if="item.status == 'closed'">
                <v-chip
                  class="ma-2"
                  color="green lighten-2"
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left>mdi-archive-outline</v-icon>
                  Cloturé
                </v-chip>
              </span>
              <template v-if="item.status == 'pending'">
                <span
                  v-if="
                    item.registrant == 'camo_cao' && item.pilotOwner == false
                  "
                >
                  <v-chip
                    class="ma-2"
                    color="red lighten-2"
                    label
                    small
                    text-color="white"
                  >
                    <v-icon small left>mdi-book-clock</v-icon>
                    En attente de fermeture
                  </v-chip>
                </span>
                <span v-else>
                  <v-chip
                    class="ma-2"
                    color="orange lighten-2"
                    label
                    small
                    text-color="white"
                  >
                    <v-icon small left>mdi-book-clock</v-icon>
                    En attente de signature
                  </v-chip>
                </span>
              </template>
              <span v-if="item.status == 'in_progress'">
                <v-chip
                  class="ma-2"
                  color="blue lighten-2"
                  label
                  small
                  text-color="white"
                >
                  <v-icon small left>mdi-pencil</v-icon>
                  En cours d'élaboration
                </v-chip>
              </span>
            </template>
            <template v-slot:item.part="{ item }">
              <span v-if="item.part == 'part_ml'">PART ML</span>
              <span v-if="item.part == 'part_m'">PART M</span>
            </template>
            <template v-slot:item.createdBy="{ item }">
              <span v-if="item.createdBy">
                {{ item.createdBy.firstName }}
              </span>
            </template>
            <template v-slot:item.expiredAt="{ item }">
              <v-chip
                class="ma-2"
                :color="expiredColor(item.expiredAt)"
                small
                text-color="white"
                v-if="item.expiredAt"
              >
                {{ item.expiredAt | moment("DD/MM/YYYY") }}
              </v-chip>
            </template>

            <template v-slot:item.view="{ item }">
              <v-btn small icon :to="{ name: 'pe', params: { id: item.id } }"
                ><v-icon color="primary">mdi-folder-eye-outline</v-icon></v-btn
              >
              <v-btn
                icon
                v-if="item.signedProgramme"
                :href="item.signedProgramme"
                target="_blank"
              >
                <v-icon color="red">mdi-file-pdf-box</v-icon>
              </v-btn>
              <v-btn icon v-else>
                <v-icon class="grey--text">mdi-timer-sand</v-icon>
              </v-btn>
            </template>

            <!-- <template v-slot:item.actions="{ item }">
              <v-tooltip top max-width=350px>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="primary--text"
                    icon
                    :to="{ name: 'review_pe', params: { id: item.id } }"
                  >
                    <v-icon v-on="on">mdi-restart</v-icon>
                  </v-btn>
                </template>
                <span > La Fonction "Réviser" est à utiliser quand aucune données du programme d'entretien n'a subit de changement. Cela génére un révision incorporé au programme d'entretien en cours.</span>
              </v-tooltip>
              <v-tooltip top max-width=350px>
                <template v-slot:activator="{ on }">
                  <v-btn class="primary--text" icon v-on="on">
                    <v-icon>mdi-file-multiple-outline</v-icon>
                  </v-btn>
                </template>
                <span>La fonction "Renouveller" est à utiliser quand des données du programme d'entretien sont à modifier. Celà génére un nouveau Programme d'entretien.</span>
              </v-tooltip>
            </template> -->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
</style>

<script>
import { maintenanceProgrammesQuery } from "../../graphql/maintenance_programme/maintenance_programmes";

export default {
  name: "GestionPE",
  components: {
  },
  metaInfo() {
    return {
      title: "PE",
    };
  },
  data() {
    return {
      maintenanceProgrammes: [],
      search: "",
      isLoading: true,
      headers: [
        { text: "", value: "view", sortable: false, width: "100px" },
        { text: "DATE LIMITE", value: "expiredAt", width: "100px" },
        { text: "STATUT", value: "status", width: "175px" },
        { text: "CREE LE", value: "createdAt", width: "75px" },
        { text: "PAR", value: "createdBy", width: "75px" },
        { text: "REFERENCE", value: "reference", width: "200px" },
        { text: "TYPE", value: "type", width: "60px" },
        { text: "IMMAT", value: "aircraft.immatriculation", width: "100px" },
        {
          text: "MODELE",
          value: "aircraft.cell.partModel.fullName",
          width: "350px",
        },
        { text: "CLIENT", value: "aircraft.contact.name" },
      ],
      listingFilter: "all",
      listingOptions: [
        { text: "Tout", value: "all" },
        { text: "En cours d'élaboration", value: "in_progress" },
        { text: "En attente de signature", value: "pending" },
        { text: "En attente de fermeture", value: "pending_camo" },
        { text: "Cloturé", value: "closed" },
      ],
      dateFilter: "all",
      dateOptions: [
        { text: "Tout", value: "all" },
        { text: "Année en cours", value: "current_year" },
        { text: "Année -1", value: "year_1" },
        { text: "Année -2", value: "year_2" },
      ],
      breadItems: [
        {
          text: "Accueil",
          disabled: false,
          href: "root",
        },
        {
          text: "Gestion de navigabilité",
          disabled: false,
        },
        {
          text: "Programme d'entretien",
          disabled: false,
        },
      ],
    };
  },

  //TODO green si avant date superieur a 2 mois
  //TODO orange si -2 mois
  //TODO rouge si depassé

  methods: {
    expiredColor(expiredAt) {
      const exp = new Date(expiredAt);
      const monthAgo = new Date(
        exp.getFullYear(),
        exp.getMonth() - 2,
        exp.getDate()
      );
      if (exp < Date.now()) {
        return "red lighten-2";
      }
      if (monthAgo < Date.now()) {
        return "orange lighten-2";
      }
      return "green lighten-2";
    },
  },
  apollo: {
    maintenanceProgrammes: {
      query: maintenanceProgrammesQuery,
      variables() {
        return {
          status: this.listingFilter,
          year: this.dateFilter,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>