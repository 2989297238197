<template>
  <v-container>
    <v-col cols="4" offset="4" class="py-0"> 
      <v-card max-width="400" outlined class="py-0"  >
        <v-list class="py-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
              {{ workOrderItemStop.itemStop.stop.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn :class="buttonClass" small dark @click="save()">{{ workOrderItemStop.status == 'done' ? 'Dévalider' : 'Valider' }}</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>        
       

        <!--<v-col cols="4">
          <div class="ml-6 mt-4">
            <v-radio-group v-model="action" :row="true">
              <v-radio label="Effectué" value="done"></v-radio>
              <v-radio label="Reporté" value="report"></v-radio>
            </v-radio-group>
          </div>
           <div class="text-h5 ml-6 mt-4">
            <tiptap-vuetify
                v-model="observation"
                :extensions="extensions"
                :card-props="{ outlined: true }"
                :toolbar-attributes="{
                  dense: true,
                  color: '#eceff1',
                }"
                placeholder="Observations/Actions"
              />
          </div> 
        </v-col>-->
 
      </v-card>
    </v-col> 
  </v-container>
</template>
<script>
import { updateWorkOrderItemStopMutation } from "../../graphql/work_order/update_work_order_item_stop";

// import {
//   TiptapVuetify,
//   Bold,
//   Italic,
//   Underline,
//   BulletList,
//   OrderedList,
//   ListItem,
//   HardBreak,
// } from "tiptap-vuetify";

export default {
  name: "RetourButeeVisiteComponent",
  props: ["workOrderItemStop"],
  components: {  },
  data() {
    return {
      action: null,
      observation: null,
      // extensions: [
      //   Bold,
      //   Italic,
      //   Underline,
      //   ListItem,
      //   BulletList,
      //   OrderedList,
      //   HardBreak,
      // ],
    };
  },
  methods: {
    save() {
      this.$apollo.mutate({
        mutation: updateWorkOrderItemStopMutation,
        variables: {
          input: {
            id: parseInt(this.workOrderItemStop.id, 10),
            attributes: {
              observation: this.observation,
              status: this.workOrderItemStop.status == 'done' ? 'new' : 'done',
            },
          },
        },
      }).then(() => {
        this.$emit("change_work_order_items", true);
      });
    },
  },
  computed: {
    buttonClass() {
      return this.workOrderItemStop.status == 'done' ? 'error' : 'primary';
    },
  }, 
  apollo: {},
  created() {
    this.observation = this.workOrderItemStop.observation;
    this.action = this.workOrderItemStop.status;
  },
};
</script>