<template>
  <v-container fluid>
    <v-sheet>
      <v-col cols="12" class="mt-4">
        <administratif-component :OT="OT"></administratif-component>
      </v-col>
    </v-sheet>
  </v-container>
</template>
<script>
import AdministratifComponent from "./AdministratifComponent.vue";

export default {
  name: "RetourAdministratif",
  props: ["OT"],
  components: {
    AdministratifComponent,
  },
};
</script>