<template>
  <v-col cols="10" offset="1">
    <v-sheet outlined>
      <v-card-title class="titre--text">
         <v-spacer></v-spacer>
        <v-row>
        <v-col cols="5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>          
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left text-uppercase">Statut</th>
              <th class="text-left text-uppercase">Entrée/sortie</th>
              
              <th class="text-left text-uppercase">Immat.</th>
              <th class="text-left text-uppercase">Modèle</th>
              <th class="text-left text-uppercase" style="width:200px">Client</th>
              
            </tr>
          </thead>
          <tbody>
            <tr v-for="aircraft in lastAircrafts" :key="aircraft.id">
              
              <td>
                <v-icon color="red" v-if="aircraft.aircraftContracts[0].closedAt"
                  >mdi-airplane-takeoff</v-icon
                >
                <v-icon color="green" v-else
                  >mdi-airplane-landing</v-icon
                >

              </td>
              <td v-if="aircraft.aircraftContracts[0].closedAt" >{{ aircraft.aircraftContracts[0].closedAt | moment("DD/MM/YYYY") }}</td>
              <td v-else>{{ aircraft.aircraftContracts[0].start | moment("DD/MM/YYYY") }}</td>
              <td>
                <v-btn
                  text
                  :to="{
                    name: 'aeronef',
                    params: { immatriculation: aircraft.immatriculation, page: 'info' },
                  }"
                  >{{ aircraft.immatriculation }}</v-btn
                >
              </td>
              <td>
                {{ aircraft.cell.partModel.tcHolder.name }}
                {{ aircraft.cell.partModel.reference }}
              </td>
              <td v-if="aircraft.contact"> {{ aircraft.contact.name }}</td>
              <td v-else ></td>
              
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </v-col>
</template>

<script>
import { lastAircraftsQuery } from "../../graphql/aircraft/last_aircrafts";


export default {
  name: "LastImmat",
  data() {
    return {
      lastAircrafts: [],
    };
  },
  apollo: {
    lastAircrafts: {
      query: lastAircraftsQuery,
      variables() {
        return {
          limit: 100,
        };
      },
    },
  },
};
</script>
