<template>
	<v-container fluid >
		<v-row  >
			<v-col cols="colsize" class="background">
				<nombre-contrat></nombre-contrat>
			</v-col>
				
			<v-col cols="colsize" class="background">
				<nombre-aeronef></nombre-aeronef>
			</v-col>

			<v-col cols="colsize" class="background">
				<repartition></repartition>
			</v-col>

			<v-col cols="colsize" class="background">
				<nombre-cen></nombre-cen>
			</v-col>

			<v-col cols="colsize" class="background">
				
			</v-col>

			<v-col cols="colsize" class="background">
				
			</v-col>
		</v-row>

	</v-container>
</template>

<style>


</style>

<script>
import NombreAeronef from './nombreAeronef.vue';
import NombreContrat from './nombreContrat.vue';
import Repartition from './repartition.vue';
import NombreCen from './nombreCen.vue';

export default {
  components: { NombreAeronef, NombreContrat, Repartition, NombreCen },
	data() {
	
    return {

		}
	},
	computed: {		
		colsize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 3;
        case "md":
          return 3;
        case "lg":
          return 4;
        case "xl":
          return 4;
      }
      return "3";
    },	
	},
};

</script>