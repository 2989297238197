import gql from 'graphql-tag';

export const workOrderFragment = {
    fragments: {
      workOrder: gql`
      fragment workOrder on WorkOrder {
        id
        orderType
        reference
        createdAt
        generateInvoice
        invoiced
        subOrderType
        otAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
        signedWorkOrderAttachment {
          id
          name
          fileUrl
          fileSignedId
          createdAt
        }
        aprsDate
        status
        user {
          id
          name
        }
        closeBy {
          id
          name
        }
        aircraft {
          id
          immatriculation
          contact {
              id
              name
          }
          cell {
            id
            flyValue
            serialNumber
            partModel {
              id
              tcHolder {
                id
                name
              }
              reference
              designation
              type
              typeRaw
              category {
                id
                name
              }
              ata {
                id
                chapter
              }
            }
          }
          motors {
            id
            flyValue
            partModel {
              id
              tcHolder {
                id
                name
              }
              reference
              designation
              type
              typeRaw
              category {
                id
                name
              }
              ata {
                id
                chapter
              }
            }
          }
          propellers {
            id
            flyValue
            partModel {
              id
              tcHolder {
                id
                name
              }
              reference
              designation
              type
              typeRaw
              category {
                id
                name
              }
              ata {
                id
                chapter
              }
            }
          }
        }
        workshop {
          id
          name
          easaNumber
        }
        contact {
          id
          name
        }
        workOrderItems {
          id
          question
          observation
          status
        }
        workOrderDocuments {
          id
          name
        }
        workOrderItemStops {
          id
          observation
          status
          itemStop {
            id
            priority
            aircraftPart {
              id
            }
            documentProcessing {
              id
            }
            stop {
              id
              name
              value
              inspection
            }
          }
        }
        returnMechanics
        returnWorkshopTools
      }` 
    }
}