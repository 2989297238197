import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
/* GestNav*/
import GestionHome from '../views/TableauBord/GestionHome.vue'
import TraitementDoc from '../views/Traitement/TraitementDoc.vue'
import GestionPiece from '../views/GestionPiece.vue'
/* Statistique */
import GestionStat from '../views/Statistique/GestionStat.vue'
/* Document*/
import Documents from '../views/Document/Documents.vue'
import Manuels from '../views/Document/Manuels.vue'
import Document from '../views/Document/Document.vue'
import NewDocument from '../views/Document/NewDocument.vue'
import TcHolderAccess from '../views/Document/TcHolder_access.vue'
import Lexique from '../views/Document/Lexique.vue'
/* Aéronef*/
import ListeImmat from '../views/Aeronef/ListeImmat.vue'
import Aeronef from '../views/Aeronef/Aeronef.vue'
import AeronefNew from '../views/Aeronef/AeronefNew.vue'
import UpdateAeronef from '../views/Aeronef/UpdateAeronef.vue'
import NewPart from '../views/Aeronef/Part/NewPart.vue'
import Part from '../views/Aeronef/Part/Part.vue'
/* TcHolder*/
import TcHolder from '../views/TcHolder/TcHolder.vue'
/*Admin */
import Admin from '../views/Admin/Index.vue'
import NewAta from '../views/Admin/Ata/NewAta.vue'
import UpdateAta from '../views/Admin/Ata/UpdateAta.vue'
import NewButee from '../views/Admin/Butee/NewButee.vue'
import UpdateButee from '../views/Admin/Butee/UpdateButee.vue'
import NewManufacturer from '../views/Admin/Constructeur/NewManufacturer.vue'
import UpdateManufacturer from '../views/Admin/Constructeur/UpdateManufacturer.vue'
import NewModele from '../views/Admin/Modele/NewModele.vue'
import UpdateModele from '../views/Admin/Modele/UpdateModele.vue'
import NewModelePart from '../views/Admin/Modele/NewModelePart.vue'
import UpdateModelePart from '../views/Admin/Modele/UpdateModelePart.vue'
import NewModeleSerie from '../views/Admin/Modele/NewModeleSerie.vue'
import UpdateModeleSerie from '../views/Admin/Modele/UpdateModeleSerie.vue'
import NewTcHolder from '../views/Admin/TcHolder/NewTcHolder.vue'
import UpdateTcHolder from '../views/Admin/TcHolder/UpdateTcHolder.vue'
import NewCategorie from '../views/Admin/Categorie/NewCategorie.vue'
import UpdateCategorie from '../views/Admin/Categorie/UpdateCategorie.vue'
import NewAction from '../views/Admin/Action/NewAction.vue'
import UpdateAction from '../views/Admin/Action/UpdateAction.vue'
import NewCenVerif from '../views/Admin/CenVerification/NewCenVerif.vue'
import UpdateCenVerif from '../views/Admin/CenVerification/UpdateCenVerif.vue'
import NewWorkshopToolModel from '../views/Admin/WorkshopTool/NewWorkshopToolModel.vue'
import NewTemplate from '../views/Admin/Template/NewTemplate.vue'
import Template from '../views/Admin/Template/Template.vue'
/* CEN */
import GestionCEN from '../views/CEN/GestionCEN.vue'
import CEN from '../views/CEN/CEN.vue'
import UpdateCEN from '../views/CEN/UpdateCen.vue'
import CENRealisation from '../views/CEN/CENRealisation.vue'
import DocumentPdf from '../views/CEN/DocumentPdf.vue'
/* Livret */
import GestionLivret from '../views/Livret/GestionLivret.vue'
import NewLivret from '../views/Livret/NewLivret.vue'
import UpdateLivret from '../views/Livret/UpdateLivret.vue'
import SentLivret from '../views/Livret/SentLivret.vue'
/* Programme entretien */
import GestionPE from '../views/PE/GestionPE.vue'
import Pe from '../views/PE/PE.vue'
import NewPE from '../views/PE/NewPE.vue'
import ReviewPE from '../views/PE/ReviewPE.vue'
/* Ordre Travail */
import NewOT from "../views/OT/NewOT.vue"
import Retour from "../views/OT/Retour.vue"
import Ot from "../views/OT/OT.vue"
/* contact */
import Contact from '../views/Contact/Contact.vue'
import Contacts from '../views/Contact/Contacts.vue'
import Ateliers from '../views/Contact/Ateliers.vue'
import NewContact from '../views/Contact/NewContact.vue'
/* utlisateur */
import User from '../views/User/User.vue'
import Users from '../views/User/Users.vue'
import NewUser from '../views/User/NewUser.vue'
/* contrat */
import Contrat from '../views/Contrat/Contrat.vue'
import Contrats from '../views/Contrat/Contrats.vue'
import NewContrat from '../views/Contrat/NewContrat.vue'
import NewContratImmat from '../views/Contrat/NewContratImmat.vue'
import UpdateContrat from '../views/Contrat/UpdateContrat.vue'
/*requete*/
import Requete from '../views/Requete/Index.vue'
/*guide*/
import Guide from '../views/Guide.vue'
/*veille*/
import SuiviVeille from '../views/Veille/SuiviVeille.vue'
import SuiviDoc from '../views/Veille/SuiviDoc.vue'
/*qualité*/
import Audits from '../views/Audit/Audits.vue'
import Audit from '../views/Audit/Audit.vue'
import NewAudit from '../views/Audit/NewAudit.vue'
import SuiviEcarts from '../views/Audit/SuiviEcarts.vue'
import PlanningAudit from '../views/Audit/PlanningAudit.vue'
import NewEcart from '../views/Audit/NewEcart.vue'
import SuiviIgame from '../views/Audit/SuiviIgame.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    component: Home
  },
  /* GestNav*/
  {
    path: '/gestion_nav',
    name: 'GestionHome',
    component: GestionHome
  },
  {
    path: '/traitement_doc',
    name: 'TraitementDoc',
    component: TraitementDoc
  },
  {
    path: '/pieces',
    name: 'GestionPiece',
    component: GestionPiece
  },
  /* statistique*/
  {
    path: '/gestion_stat',
    name: 'GestionStat',
    component: GestionStat
  },
  /* Bibliothèque*/
  {
    path: '/documents',
    name: 'documents',
    component: Documents
  },
  {
    path: '/document/new',
    name: 'new_document',
    component: NewDocument
  },
  {
    path: '/document/:id',
    name: 'document',
    component: Document
  },
  {
    path: '/document/:id/modifier',
    name: 'document_edit',
    component: NewDocument
  },
  {
    path: '/gestion_manuel',
    name: 'gestion_manuel',
    component: Manuels
  },
  {
    path: '/TcHolder_access',
    name: 'tcholder_access',
    component: TcHolderAccess
  },
  {
    path: '/Lexique',
    name: 'lexique',
    component: Lexique
  },
  /* Aéronef*/
  {
    path: '/liste_immat',
    name: 'ListeImmat',
    component: ListeImmat
  },
  {
    path: '/aeronef/nouveau',
    name: 'aeronef_new',
    component: AeronefNew
  },
  {
    path: '/aeronef/:immatriculation/modifier',
    name: 'aeronef_edit',
    component: UpdateAeronef
  },
  {
    path: '/aeronef/:immatriculation/piece/nouveau',
    name: 'aeronef_part_new',
    component: NewPart
  },
  {
    path: '/aeronef/:immatriculation/piece/:id',
    name: 'aeronef_part',
    component: Part
  },
  {
    path: '/aeronef/:immatriculation/piece/:id/edit',
    name: 'aeronef_part_edit',
    component: NewPart
  },
  {
    path: '/aeronef/:immatriculation/:page',
    name: 'aeronef',
    component: Aeronef
  },  
  /* Part */
  {
    path: '/piece/:id',
    name: 'part',
    component: Part
  },
  {
    path: '/piece/:id/edit',
    name: 'part_edit',
    component: NewPart
  },
  /* TcHolder*/
  {
    path: '/tc_holder/:id',
    name: 'tc_holder',
    component: TcHolder
  },
  /* Admin*/
  {
    path: '/admin/',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/admin/ata/nouveau',
    name: 'ata_new',
    component: NewAta
  },
  {
    path: '/admin/ata/:id/modifier',
    name: 'ata_edit',
    component: UpdateAta
  },
  {
    path: '/admin/butee/nouveau',
    name: 'butee_new',
    component: NewButee
  },
  {
    path: '/admin/butee/:id/modifier',
    name: 'butee_edit',
    component: UpdateButee
  },
  {
    path: '/admin/Constructeur/nouveau',
    name: 'manufacturer_new',
    component: NewManufacturer
  },
  {
    path: '/admin/Constructeur/:id/modifier',
    name: 'manufacturer_edit',
    component: UpdateManufacturer
  },
  {
    path: '/admin/Modele/nouveau',
    name: 'modele_new',
    component: NewModele
  },
  {
    path: '/admin/Modele/:id/modifier',
    name: 'modele_edit',
    component: UpdateModele
  },
  {
    path: '/admin/ModelePart/nouveau',
    name: 'modelepart_new',
    component: NewModelePart
  },
  {
    path: '/admin/ModelePart/:id/modifier',
    name: 'modelepart_edit',
    component: UpdateModelePart
  },
  {
    path: '/admin/ModeleSerie/nouveau',
    name: 'modeleserie_new',
    component: NewModeleSerie
  },
  {
    path: '/admin/ModeleSerie/:id/modifier',
    name: 'modeleserie_edit',
    component: UpdateModeleSerie
  },
  {
    path: '/admin/TcHolder/nouveau',
    name: 'tcholder_new',
    component: NewTcHolder
  },
  {
    path: '/admin/TcHolder/:id/modifier',
    name: 'tcholder_edit',
    component: UpdateTcHolder
  },
  {
    path: '/admin/Categorie/nouveau',
    name: 'categorie_new',
    component: NewCategorie
  },
  {
    path: '/admin/Categorie/:id/modifier',
    name: 'categorie_edit',
    component: UpdateCategorie
  },
  {
    path: '/admin/Action/nouveau',
    name: 'action_new',
    component: NewAction
  },
  {
    path: '/admin/Action/:id/modifier',
    name: 'action_edit',
    component: UpdateAction
  },
  {
    path: '/admin/CenVerification/nouveau',
    name: 'cen_verif_new',
    component: NewCenVerif
  },
  {
    path: '/admin/CenVerification/:id/modifier',
    name: 'cen_verif_edit',
    component: UpdateCenVerif
  },
  {
    path: '/admin/workshopToolModel/nouveau',
    name: 'new_workshop_tool_model',
    component: NewWorkshopToolModel
  },
  {
    path: '/admin/workshopToolModel/edit/:id',
    name: 'edit_workshop_tool_model',
    component: NewWorkshopToolModel
  },
  {
    path: '/admin/template/nouveau',
    name: 'template_new',
    component: NewTemplate
  },  
  {
    path: '/admin/template',
    name: 'template',
    component: Template
  }, 
  {
    path: '/cens',
    name: 'cens',
    component: GestionCEN
  },

  {
    path: '/cen/:id',
    name: 'cen',
    component: CEN
  },
  {
    path: '/cen/:id/modifier',
    name: 'cen_edit',
    component: UpdateCEN
  },
  {
    path: '/cen/:id/realisation',
    name: 'cen_review',
    component: CENRealisation
  },
  {
    path: '/cen/:id/cloturer',
    name: 'cen_closure',
    component: DocumentPdf
  },
 
  /* Livret*/
  {
    path: '/livrets',
    name: 'livrets',
    component: GestionLivret
  },
  {
    path: '/livret/nouveau',
    name: 'livret_new',
    component: NewLivret
  },
  {
    path: '/livret/:id/modifier',
    name: 'livret_edit',
    component: UpdateLivret
  },
  {
    path: '/livret/renvoyer',
    name: 'livret_sent',
    component: SentLivret
  },
  /* Programme d'entretien */
  {
    path: '/pe',
    name: 'pes',
    component: GestionPE
  },
  {
    path: '/pe/nouveau',
    name: 'new_pe',
    component: NewPE
  },
  {
    path: '/pe/edit/:id',
    name: 'edit_pe',
    component: NewPE
  },
  {
    path: '/pe/:id/reviser/',
    name: 'review_pe',
    component: ReviewPE
  },
  {
    path: '/pe/:id',
    name: 'pe',
    component: Pe
  },
  /* ordre travail */  
  {
    path: '/ot/nouveau/:immatriculation',
    name: 'new_ot',
    component: NewOT
  },
  {
    path: '/ot/edit/:id',
    name: 'edit_ot',
    component: NewOT
  },
  {
    path: '/ot/:id/retour/',
    name: 'retour_ot',
    component: Retour,
  },
  {
    path: '/ot/:id',
    name: 'ot',
    component: Ot
  },
  /* contact */
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts
  },
  {
    path: '/ateliers',
    name: 'ateliers',
    component: Ateliers
  },
  {
    path: '/contact/new',
    name: 'new_contact',
    component: NewContact
  },
  {
    path: '/contact/:id',
    name: 'contact',
    component: Contact
  },
  {
    path: '/contact/:id/modifier',
    name: 'contact_edit',
    component: NewContact
  },
  /* utilisateur */
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/user/new',
    name: 'new_user',
    component: NewUser
  },
  {
    path: '/user/:id',
    name: 'user',
    component: User
  },
  /* Contrat */
  {
    path: '/contrat/new',
    name: 'new_contrat',
    component: NewContrat
  },
  {
    path: '/contrat/:id',
    name: 'contrat',
    component: Contrat
  },
  {
    path: '/contrat/:id/edit',
    name: 'edit_contrat',
    component: UpdateContrat
  },
  {
    path: '/contrats',
    name: 'contrats',
    component: Contrats
  },
  {
    path: '/contrat/:id/immat',
    name: 'new_contrat_immat',
    component: NewContratImmat
  },
  /* Requete */
  {
    path: '/requete/',
    name: 'Requete',
    component: Requete
  },
  /* Guide */
  {
    path: '/guide',
    name: 'guide',
    component: Guide
  },
  /* veille */
  {
    path: '/SuiviVeille',
    name: 'suivi_veille',
    component: SuiviVeille
  },
  {
    path: '/SuiviDoc',
    name: 'suivi_doc',
    component: SuiviDoc
  },
  /* Audit */
  {
    path: '/Audits',
    name: 'audits',
    component: Audits
  },
  {
    path: '/Audit/:id',
    name: 'audit',
    component: Audit
  },
  {
    path: 'Audit/New',
    name: 'new_audit',
    component: NewAudit
  },
  {
    path: '/PlanningAudit',
    name: 'planning_audit',
    component: PlanningAudit
  },
  {
    path: '/SuiviEcarts',
    name: 'suivi_ecarts',
    component: SuiviEcarts
  },
  {
    path: 'Audit/Ecart/New',
    name: 'new_ecart',
    component: NewEcart
  },
  {
    path: '/SuiviIgame',
    name: 'suivi_igame',
    component: SuiviIgame
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
