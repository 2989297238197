<template>
	<v-container fluid >
		<v-row  >
			<v-col cols="colsize" class="background">
				<entrant-sortant></entrant-sortant>
			</v-col>

			<v-col cols="colsize" class="background">
				<archive-reason></archive-reason>		
			</v-col>

			<v-col cols="colsize" class="background">

			</v-col>

			<v-col cols="colsize" class="background">

			</v-col>
			<v-col cols="colsize" class="background">
				
			</v-col>
		</v-row>

	</v-container>
</template>

<style>

v-col {
	background-color: #f4f5fa;
}
</style>

<script>
import EntrantSortant from "./EntrantSortant.vue";
import ArchiveReason from "./ArchiveReason.vue";

export default {
  components: { EntrantSortant, ArchiveReason, },
	data() {
		return {

		}
	},
	computed: {		
		colsize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 3;
        case "md":
          return 3;
        case "lg":
          return 4;
        case "xl":
          return 4;
      }
      return "3";
    },	
	},
};

</script>