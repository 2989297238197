var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"mb-2",attrs:{"outlined":"","rounded":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"7"}},[(_vm.workOrderItemStop.itemStop.documentProcessing !== null)?_c('div',{staticClass:"text-h6 ml-6 mt-4"},[_vm._v(" "+_vm._s(_vm.workOrderItemStop.itemStop.documentProcessing.document.number)+" - "+_vm._s(_vm.workOrderItemStop.itemStop.documentProcessing.document.title)+" ")]):_vm._e(),(
            _vm.workOrderItemStop.itemStop.aircraftPart !== null &&
            _vm.workOrderItemStop.itemStop.stop.inspection == false
          )?_c('div',{staticClass:"mt-1"},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.workOrderItemStop.itemStop.aircraftPart.partModel.ata.chapter)+" ")]),(_vm.workOrderItemStop.itemStop.aircraftPart.partModel.fullName)?_c('span',{staticClass:"text-h6"},[_vm._v("- "+_vm._s(_vm.workOrderItemStop.itemStop.aircraftPart.partModel.fullName))]):_vm._e(),_c('br'),(_vm.workOrderItemStop.itemStop.aircraftPart.serialNumber)?_c('span',{staticClass:"text-body-1 editor ml-6"},[_vm._v(" s/n: "+_vm._s(_vm.workOrderItemStop.itemStop.aircraftPart.serialNumber))]):_vm._e()]):_vm._e(),(_vm.workOrderItemStop.itemStop.documentProcessing !== null)?_c('div',{staticClass:"text-body-1 editor ml-6"},[_vm._v(_vm._s(_vm.workOrderItemStop.itemStop.stop.name)+" : "+_vm._s(_vm.workOrderItemStop.itemStop.action.name)+" ")]):_vm._e(),(
            _vm.workOrderItemStop.itemStop.aircraftPart !== null &&
            _vm.workOrderItemStop.itemStop.stop.inspection == false
          )?_c('div',{staticClass:"text-body-1 editor ml-6"},[_vm._v(" "+_vm._s(_vm.workOrderItemStop.itemStop.stop.name)+" : "+_vm._s(_vm.workOrderItemStop.itemStop.action.name)+" ")]):_vm._e()]),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"2"}},[(
            _vm.workOrderItemStop.itemStop.aircraftPart !== null &&
            _vm.workOrderItemStop.itemStop.stop.inspection == false
          )?_c('div',[_c('v-btn',{staticClass:"my-3",class:_vm.buttonClass,attrs:{"small":"","block":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.workOrderItemStop.status == 'done' ? 'Renouveller butée' : 'Renouveller butée'))]),_c('replace-modal',{attrs:{"aircraftPart":_vm.workOrderItemStop.itemStop.aircraftPart,"OT":_vm.workOrderItemStop.workOrder}})],1):_vm._e()]),_c('v-col',{staticClass:"mr-2",attrs:{"cols":"2"}},[(
            _vm.workOrderItemStop.itemStop.aircraftPart !== null &&
            _vm.workOrderItemStop.itemStop.stop.inspection == false
          )?_c('div',[_c('v-btn',{staticClass:"my-3",attrs:{"small":"","block":"","color":"primary"}},[_vm._v("Reporter")]),_c('depose-modal',{attrs:{"aircraftPart":_vm.workOrderItemStop.itemStop.aircraftPart}}),_c('pose-modal',{attrs:{"aircraft":_vm.workOrderItemStop.workOrder.aircraft}})],1):_vm._e(),(_vm.workOrderItemStop.itemStop.documentProcessing !== null)?_c('div',[_c('v-btn',{staticClass:"my-3",class:_vm.buttonClass,attrs:{"small":"","block":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.workOrderItemStop.status == 'done' ? 'Dévalider' : 'Valider'))]),_c('v-btn',{staticClass:"my-3",attrs:{"small":"","block":"","color":"primary"}},[_vm._v("Modifier")])],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }