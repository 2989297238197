import gql from 'graphql-tag';

export const maintenanceProgrammeFragment = {
    fragments: {
      maintenanceProgramme: gql`
        fragment maintenanceProgramme on MaintenanceProgramme {
          annexA
          annexB
          annexC
          annexD
          closedAt
          conform
          createdAt
          exportedAt
          guide3
          guide3Conclusion
          guide3Description
          id
          maintenanceAd
          maintenanceAlternative
          maintenanceEquipment
          maintenanceExploitation
          maintenanceMandatory
          maintenanceOther
          maintenanceRepair
          maintenanceSpecific
          maintenanceStop
          maintenanceTbo
          maintenanceAdData {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          maintenanceAlternativeData {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          maintenanceEquipmentData {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          maintenanceExploitationData {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          maintenanceMandatoryData {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          maintenanceOtherData {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          maintenanceRepairData {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          maintenanceSpecificData {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          maintenanceStopData {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          maintenanceTboData {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          part
          pilotOwner
          pilots
          reference
          registrant
          status
          updatedAt
          programmeFile
          checklistFile
          signedProgramme
          signedAt
          expiredAt
          manuals
          annexDData
          owners
          replaceBy {
            id
            reference
          }
          createdBy {
            id
            firstName
            lastName
          }
          validatedAt
          validatedBy {
            id
            firstName
            lastName
          }
          dah {
            id
            description
            reference
            task
            taskAlt
            interval
            intervalAlt
          }
          aircraft {
            id
            immatriculation
            contact {
              id
              name
            }
            cell {
              id
              serialNumber              
              partModel {
                id
                reference
                fullName
                tcHolder {
                  id
                  name
                }
              }
            }
            motors {
              id
            }
            propellers {
              id
            }
          }
      }`
    }
  }